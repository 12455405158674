import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Table } from 'flowbite-react';
import { LinkIcon } from '@heroicons/react/24/outline';
import { Modal } from 'flowbite-react';
import { AiOutlineDisconnect } from 'react-icons/ai';

import Spinner from '../Spinner';
import { CustomError } from '../../utils/errors';
import { useUser } from '../../UserContext';

/**
 * Component for managing self-hosted servers in the Tailor application for BYO Cloud.
 *
 * @returns {JSX.Element} The TailorSelfHosting component.
 */
const TailorSelfHosting = () => {
  const [serverName, setServerName] = useState('');
  const { customAxios } = useUser();
  const [shownPage, setShownPage] = useState('instructions');
  const [servers, setServers] = useState([]);
  const [newServer, setNewServer] = useState(null);
  const [serverIp, setServerIp] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedServer, setSelectedServer] = useState(null);
  const [showAddUrlModal, setShowAddUrlModal] = useState(false);
  const [serverNameError, setServerNameError] = useState('');

  const fetchServers = async () => {
    setLoading(true);
    try {
      const response = await customAxios.get('tailor/v1/hosted_servers');
      if (response.data.message.length > 0) {
        setShownPage('servers');
        setServers(response.data.message);
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Error fetching servers, try again later.', {
        id: 'network-error-servers',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setServerName('');
    setNewServer(null);
    setServerIp('');
    setServerNameError('');
    if (shownPage === 'servers') {
      fetchServers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownPage]);

  const handleCreateServer = async (e) => {
    e.preventDefault();
    if (!serverName) {
      setServerNameError('Server name is required');
      return;
    } else if (serverName.includes(' ')) {
      setServerNameError('Server name cannot contain spaces');
      return;
    } else if (serverName.toLowerCase() === 'tromero') {
      setServerNameError(`Server name cannot be "${serverName}"`);
      return;
    }
    try {
      const response = await customAxios.post('tailor/v1/hosted_server', {
        server_name: serverName,
      });
      if (response.status === 201) {
        setNewServer(response.data.server);
        toast.success('Server created successfully');
      } else {
        throw new CustomError({
          message: 'Failed to create server',
          code: 500,
        });
      }
    } catch (error) {
      toast.error('Failed to create server, try again later.');
      if (import.meta.env.DEV) {
        console.error('Failed to create server. Error: ', error);
      }
    }
  };

  const handleSetServerName = (e) => {
    setServerName(e.target.value);
    setServerNameError('');
  };

  const handleCopyKey = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Server key copied to clipboard');
  };

  const handleConnectServer = async (e) => {
    e.preventDefault();
    try {
      const response = await customAxios.post(
        `tailor/v1/hosted_server/${newServer.id}/start`,
        {
          url: serverIp,
        },
      );
      if (response.status === 200) {
        toast.success('Server connected successfully');
        setShowAddUrlModal(false);
        fetchServers();
      } else {
        throw new CustomError({
          message: 'Failed to connect server',
          code: 500,
        });
      }
    } catch (error) {
      toast.error('Failed to connect server, try again later.');
      if (import.meta.env.DEV) {
        console.error('Failed to connect server. Error: ', error);
      }
    }
  };

  const handleDeleteServer = async (serverId) => {
    try {
      const response = await customAxios.post(
        `tailor/v1/hosted_server/${serverId}/stop`,
        {},
      );
      if (response.status === 200) {
        if (import.meta.env.DEV) {
          console.log('Server stopped successfully', response.data);
        }
        toast.success('Server stopped successfully');
        setServers((prevServers) =>
          prevServers.filter((server) => server.id !== serverId),
        );
      } else {
        throw new CustomError({
          message: 'Failed to delete server',
          code: 500,
        });
      }
    } catch (error) {
      toast.error('Failed to delete server, try again later.');
      if (import.meta.env.DEV) {
        console.error('Failed to delete server. Error: ', error);
      }
    } finally {
      setShowDeleteModal(false);
      setSelectedServer(null);
    }
  };

  const handleOpenAddUrlModal = (server) => {
    setNewServer(server);
    setShowAddUrlModal(true);
  };

  const renderInstructionsPage = () => {
    return (
      <div className="p-4">
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            In order to host the fine-tuned models on your own servers, you need
            to follow these steps:
          </p>
        </div>

        <div className="mt-8 text-lg font-semibold text-zinc-800">
          Step 1 <span>- Create a reference on Tromero</span>
        </div>
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            Create a reference to your server in our database. This name will be
            used to identify your server in the future.
          </p>
          <form onSubmit={handleCreateServer} className="relative w-fit">
            <label htmlFor="serverName" className="block mt-4 text-sm">
              Name <span className="text-xs text-zinc-400">(no spaces)</span>
            </label>
            <input
              id="serverName"
              type="text"
              value={serverName}
              onChange={handleSetServerName}
              placeholder="e.g. 'gcp-byoc'"
              className="max-w-full p-2 mt-2 text-sm border rounded-md w-96 min-w border-zinc-300"
            />
            {serverNameError && (
              <div className="absolute text-xs text-red-500 right-1 bottom-10">
                {serverNameError}
              </div>
            )}

            <button
              type="submit"
              className="w-32 h-10 gap-1 mt-4 text-sm font-bold bg-blue-200 rounded-md flex-center text-zinc-700 hover:bg-blue-300 active:bg-blue-400"
            >
              Create Server
            </button>
          </form>
        </div>

        <div className="mt-8 text-lg font-semibold text-zinc-800">
          Step 2 <span>- Set up the server on your cloud provider</span>
        </div>
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            After creating the server, you will receive a key. Use this key to
            create a server in your cloud provider.
          </p>
          <ol className="mt-4 list-inside">
            <li>
              Follow the instructions provided in the following repo on GitHub{' '}
              <a
                href="https://github.com/proofoftraining/byoc_launcher"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 underline underline-offset-4 hover:text-blue-700"
              >
                {' '}
                byoc_launcher
              </a>
            </li>
          </ol>
          {newServer?.id && (
            <>
              <div>
                <div className="mt-4 text-sm text-zinc-700">
                  <p>
                    <span className="font-semibold">Server Key:</span>{' '}
                    <button
                      onClick={() => handleCopyKey(newServer?.key)}
                      className="max-w-full px-3 py-1 text-lg text-left break-words border rounded-md"
                    >
                      {newServer?.key}
                    </button>
                  </p>
                </div>
              </div>

              <div className="mt-4 text-sm text-zinc-700">
                <p>
                  Now that you have the server key, you can create a server in
                  your cloud provider. Follow the instructions below to create a
                  server in your cloud provider:
                </p>
              </div>
            </>
          )}
        </div>

        <div className="mt-8 text-lg font-semibold text-zinc-800">
          Step 3 <span>- Link your server to Tromero</span>
        </div>
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            Provide us with the IP address of the server you set up, including
            the port number.
          </p>
          {newServer?.id && (
            <form onSubmit={handleConnectServer}>
              <label htmlFor="serverIp" className="block mt-4 text-sm">
                Server IP Address
              </label>
              <input
                id="serverIp"
                type="text"
                value={serverIp}
                onChange={(e) => setServerIp(e.target.value)}
                placeholder="e.g. http://7.88.450.194:5000"
                className="max-w-full p-2 mt-2 text-sm border rounded-md w-96 min-w border-zinc-300"
              />

              <button
                type="submit"
                className="w-32 h-10 gap-1 mt-4 text-sm font-bold bg-blue-200 rounded-md flex-center text-zinc-700 hover:bg-blue-300 active:bg-blue-400"
              >
                Connect Server
              </button>
            </form>
          )}
        </div>

        <div className="mt-8 text-lg font-semibold text-zinc-800">
          Step 4 <span>- Deploy models on your self hosted server</span>
        </div>
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            From now on, when you choose to deploy models, you will be given the
            option to deploy to your own linked servers.
          </p>
        </div>
      </div>
    );
  };

  const renderServersPage = () => {
    return (
      <div className="p-4">
        <div className="mt-4 overflow-x-auto text-sm text-zinc-700 max-w-7xl md:pr-48 ">
          <Table className="hidden md:block max-w-7xl">
            <Table.Head>
              <Table.HeadCell className="text-left sm:w-2/12 min-w-fit text-nowrap w-fit bg-zinc-200">
                Server Name
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-zinc-200">
                Server Key
              </Table.HeadCell>
              <Table.HeadCell className="text-center text-nowrap bg-zinc-200">
                Cloud Server URL
              </Table.HeadCell>
              <Table.HeadCell className="text-center bg-zinc-200">
                Actions
              </Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {servers.length > 0 ? (
                servers.map((server) => (
                  <Table.Row key={server.id} className="w-fit">
                    <Table.Cell className="text-left w-fit sm:w-2/12">
                      <button onClick={() => handleCopyKey(server.server_name)}>
                        {server.server_name}
                      </button>
                    </Table.Cell>
                    <Table.Cell className="text-center break-words w-96 xl:w-auto line-clamp-4">
                      <button onClick={() => handleCopyKey(server.key)}>
                        {server.key}
                      </button>
                    </Table.Cell>
                    <Table.Cell className="text-center break-words">
                      {server.url ? server.url : '-'}
                    </Table.Cell>
                    <Table.Cell className="text-center">
                      {server.url ? (
                        <button
                          aria-label="Disconnect"
                          className="text-sm text-zinc-700 hover:text-zinc-900"
                          onClick={() => {
                            setShowDeleteModal(true);
                            setSelectedServer(server.id);
                          }}
                        >
                          <AiOutlineDisconnect className="w-5 h-5" />
                        </button>
                      ) : (
                        <button
                          aria-describedby="Connect"
                          aria-label="Connect"
                          className="text-sm text-zinc-700 hover:text-zinc-900"
                          onClick={() => handleOpenAddUrlModal(server)}
                        >
                          <LinkIcon className="w-5 h-5" />
                        </button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="3">No cloud servers found</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          <div className="flex flex-col gap-4 mt-4 md:hidden">
            {servers.length > 0 ? (
              servers.map((server) => (
                <div
                  key={server.id}
                  className="relative flex flex-col w-full p-4 bg-white border rounded border-zinc-300"
                >
                  <div className="absolute top-2 right-2">
                    {server.url ? (
                      <button
                        className="text-sm text-zinc-700 hover:text-zinc-900"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setSelectedServer(server.id);
                        }}
                      >
                        <AiOutlineDisconnect className="w-5 h-5" />
                      </button>
                    ) : (
                      <button
                        className="text-sm text-zinc-700 hover:text-zinc-900"
                        onClick={() => handleOpenAddUrlModal(server)}
                      >
                        <LinkIcon className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                  <div className="items-baseline justify-between w-full">
                    <div className="text-lg font-semibold text-left text-zinc-900 grow">
                      {server.server_name}
                    </div>
                    <div className="mt-1 text-sm text-zinc-600">
                      <button
                        onClick={() => handleCopyKey(server.key)}
                        className="max-w-full text-left break-words"
                      >
                        <b>Key:</b> {server.key}
                      </button>
                    </div>
                    <div className="text-sm grow max-w-[50%] mt-1">
                      <b>URL:</b> {server.url ? server.url : '-'}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-around h-full">
                <div className="flex flex-col items-center gap-2">
                  <div>No servers found</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen pb-28 bg-zinc-50 font-dmSans">
      <header className="pt-10 lg:pt-0">
        <div className="flex justify-between h-16 p-4 text-xl font-medium text-zinc-800">
          Self Hosting - BYO Cloud
          <div></div>
        </div>
        <hr className="border-t border-zinc-300" />
      </header>

      {loading && (
        <div className="flex items-center justify-center h-96">
          <Spinner size={'36px'} borderTopColor={'gray'} />
        </div>
      )}

      {!loading && (
        <>
          <div>
            <div className="flex gap-10 p-4">
              <div
                className={`cursor-pointer w-28 flex-center text-lg text-zinc-800 ${
                  shownPage === 'instructions'
                    ? 'font-semibold underline underline-offset-4'
                    : ''
                }`}
                onClick={() => setShownPage('instructions')}
              >
                Instructions
              </div>
              <div
                className={`cursor-pointer w-28 flex-center text-lg text-zinc-800 ${
                  shownPage === 'servers'
                    ? 'font-semibold underline underline-offset-4'
                    : ''
                }`}
                onClick={() => setShownPage('servers')}
              >
                Server List
              </div>
            </div>
          </div>

          {shownPage === 'instructions' && renderInstructionsPage()}

          {shownPage === 'servers' && renderServersPage()}
        </>
      )}

      <Modal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        size="2xl"
      >
        <Modal.Header>
          <p className="font-spaceG">Un-link Server</p>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-4 space-y-6 ">
            <p>Are you sure you want to un-link this server?</p>
            <p className="mt-2">
              If you disconnect this server, any models deployed on it will no
              longer be accessible.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end w-full gap-8">
            <button
              className="flex items-center gap-1 p-2 px-3 text-sm font-bold underline rounded-md text-zinc-700 hover:underline underline-offset-4"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              className="flex items-center gap-1 p-2 px-3 text-sm font-bold bg-red-200 rounded-md text-zinc-700 hover:bg-red-300 active:bg-red-400"
              onClick={() => handleDeleteServer(selectedServer)}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAddUrlModal}
        onClose={() => setShowAddUrlModal(false)}
        size="2xl"
      >
        <Modal.Header>
          <p className="font-spaceG">Connect your Cloud Server</p>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-4 space-y-6 ">
            <p>
              Please provide the URL of your cloud server to connect it with
              Tromero.
            </p>
            <form onSubmit={handleConnectServer}>
              <label htmlFor="serverIp" className="block mt-4 text-sm">
                Server IP Address
              </label>
              <input
                id="serverIp"
                type="text"
                value={serverIp}
                onChange={(e) => setServerIp(e.target.value)}
                placeholder="e.g. http://7.88.450.194:5000"
                className="max-w-full p-2 mt-2 text-sm border rounded-md w-96 min-w border-zinc-300"
              />

              <button
                type="submit"
                className="w-32 h-10 gap-1 mt-4 text-sm font-bold bg-blue-200 rounded-md flex-center text-zinc-700 hover:bg-blue-300 active:bg-blue-400"
              >
                Connect Server
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TailorSelfHosting;
