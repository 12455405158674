import { useState } from 'react';

// import VerificationErrorModal from './VerificationErrorModal';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Switch } from '@headlessui/react';
import axios from 'axios';

import { useUser } from '../UserContext';
import SuccessModal from './Modals/SuccessModal';
import {
  checkIfUsingBannedEmailsNoApi,
  sanitizeEmail,
  saveRejectedUser,
} from '../utils/userUtils';
import { CustomError } from '../utils/errors';
// import { MdCleaningServices } from 'react-icons/md';
import { normalAxios } from '../utils/generalUtils';

const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

const SignUpStep1 = ({ onNext, formData, setFormData }) => {
  const { updateUserDetails, fetchAndUpdateUserDetails } = useUser();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordMeetsLength, setPasswordMeetsLength] = useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordHasUppercase, setPasswordHasUppercase] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [requestReceived, setRequestReceived] = useState(false);
  const [showTax, setShowTax] = useState(false);

  //sign in step error
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const updatePasswordCriteria = (password) => {
    setPasswordMeetsLength(password.length >= 8);
    setPasswordHasNumber(/\d/.test(password));
    setPasswordHasUppercase(/[A-Z]/.test(password));
  };

  const handleChange = async (e) => {
    const { name, type, checked, value } = e.target;
    setSignUpError(false);
    setErrorMessage('');
    if (name === 'business') {
      setFormData({
        ...formData,
        details: {
          ...formData.details,
          business: value,
        },
      });
    } else if (name === 'taxIdType') {
      setFormData({
        ...formData,
        details: {
          ...formData.details,
          tax_id_type: value,
        },
      });
    } else if (name === 'taxId') {
      setFormData({
        ...formData,
        details: {
          ...formData.details,
          tax_id_number: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }

    if (name === 'password') {
      updatePasswordCriteria(value);
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordError('');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showErrorModal = () => {
    setErrorModalVisible(true);
  };

  const hideErrorModal = () => {
    setErrorModalVisible(false);
  };

  const checkIfUsingBannedEmails = async (email) => {
    try {
      const res = await axios.get(
        `https://disposable.debounce.io/?email=${email}`,
      );
      if (res.data.disposable === 'true') {
        return true;
      }
    } catch (error) {
      console.error('Error checking if email is disposable: ', error);
    }
    return await checkIfUsingBannedEmailsNoApi(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Reset password error
    setPasswordError('');

    // Password validation
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      setPasswordError(
        'Password must be at least 8 characters long and include one number and one uppercase letter.',
      );
      setLoading(false);
      return;
    }

    if (formData.password !== confirmPassword) {
      setPasswordError('Passwords do not match!');
      setLoading(false);
      return;
    }

    try {
      const banned = await checkIfUsingBannedEmails(formData.email);
      if (banned) {
        await saveRejectedUser({
          first_name: formData.first_name,
          last_name: formData.last_name,
          email_address: formData.email,
        });
        throw new CustomError({
          code: 'banned.email',
          message: 'Disposable email addresses are not allowed',
        });
      }

      const formToSend = {
        ...formData,
        email: await sanitizeEmail(formData.email),
      };

      const response = await normalAxios.post('/signup', formToSend);

      if (response?.status === 201) {
        if (
          response.data.status === 'success' &&
          response.data.message === 'New account requested'
        ) {
          setShowSuccessModal(true);
        } else {
          const authHeader =
            response.headers['authorization'] ||
            response.headers['Authorization'];

          let token;
          if (
            authHeader &&
            authHeader.startsWith('Bearer ') &&
            authHeader.length > 7 &&
            typeof authHeader === 'string' &&
            authHeader.trim() !== ''
          ) {
            token = authHeader.substring(7);
          } else {
            console.error('Problems with the Token from server');
            throw new Error('There was an error logging in. Please try again.');
          }
          localStorage.setItem('token', token);
          await fetchAndUpdateUserDetails();
          setShowSuccessModal(true);
        }
      } else {
        throw new Error('Error signing up');
      }
    } catch (error) {
      handleSingUpError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSingUpError = (error) => {
    const errorToDisplay = error?.response?.data;
    console.error('Error signing up: ', error);
    if (error.code === 'ERR_NETWORK') {
      toast.error('Network error. Please try again later.', { id: 'network-error'});
      return;
    }
    setSignUpError(true);
    if (error.code === 'banned.email') {
      setErrorMessage(
        'We are unable to create an account with this email address. Please use a different one.',
      );
      return;
    }
    if (errorToDisplay.code === 'users.signup.missing_fields') {
      setErrorMessage('Please fill in all fields.');
    } else if (errorToDisplay.code === 'users.signup.already_registered') {
      setErrorMessage('We already have an account for this email address.');
    } else if (errorToDisplay.code === 'users.signup.invalid_email') {
      setErrorMessage(
        'We are unable to create an account with this email address. Please use a different one.',
      );
    } else if (errorToDisplay.code === 'users.signup.internal') {
      setErrorMessage(
        'There seems to be an error creating a user with the provided details. Please try again.',
      );
    } else {
      setSignUpError('There was an error signing up. Please try again.');
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate('/');
  };

  return (
    <div className="flex flex-row min-h-screen px-4 pt-16 lg:pt-24 justify-evenly bg-zinc-50 font-dmSans">
      <div className="flex-1 hidden max-w-md px-6 pt-16 mr-16 leftside w-96 lg:h-screen lg:block">
        {/* <div className="flex items-center pb-16 -mt-12 space-x-4 ">
          <a
            href="/marketplace"
            className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg text-slate-950 hover:bg-slate-200 dark:hover:bg-gray-700"
          >
            <svg
              className="w-6 h-6 mr-1 dark:text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div className="flex items-center font-semibold text-slate-800 dark:text-gray-200">
              <img className="w-8 h-8 mr-2" src="Noname.png" />
              Tromero Marketplace Centre
            </div>
          </a>
        </div> */}
        <div className="block p-8 border rounded-lg shadow-lg text-slate-800 bg-slate-50 dark:bg-gray-700 dark:text-slate-100">
          <h3 className="mb-1 text-2xl font-semibold">What You Get</h3>
          <p className="mb-4 font-light text-slate-700 dark:text-gray-300 sm:text-lg">
            Features at a glance
          </p>

          <ul role="list" className="space-y-4 text-left">
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Fast Iteration</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Rapid Prototyping</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Budget-Friendly Scaling</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Flexible Durations</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>State-of-the-Art Hardware</span>
            </li>

            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Zero Upfront Investment</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Reliable and fault tolerant</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex-col justify-center flex-1 w-full mx-auto rightside">
        {/* <div className="flex items-center justify-center space-x-4 lg:hidden ">
          <a
            href="#"
            className="flex items-center mb-10 text-2xl font-semibold lg:mb-0"
          >
            <img className="w-8 h-8 mr-2" src="Noname.png" />
            <span className="text-slate-900 dark:text-white ">Tromero</span>
          </a>
        </div> */}
        {/* <ol className="flex items-center justify-center mb-6 text-sm font-medium text-center text-slate-500 dark:text-gray-400 lg:mb-12 sm:text-base">
          <li className="flex items-center text-slate-600 dark:text-gray-100 sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-slate-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-slate-700">
            <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-slate-200 dark:after:text-slate-500">
              <svg
                className="w-4 h-4 mr-2 sm:mb-2 sm:w-6 sm:h-6 sm:mx-auto"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Account <span className="hidden sm:inline-flex">Info</span>
            </div>
          </li>
          <li className="flex items-center after:content-[''] after:0 after:h-1 after:border-b after:border-slate-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-slate-700">
            <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-slate-200 dark:after:text-slate-500">
              <div className="mr-2 sm:mb-2 sm:mx-auto">2</div>
              Personal <span className="hidden sm:inline-flex">Info</span>
            </div>
          </li>
        </ol> */}
        <h1 className="p-4 mb-4 text-2xl font-extrabold leading-tight tracking-tight text-slate-900 sm:mb-6 dark:text-white sm:p-0">
          Account Details
        </h1>
        {/*  */}
        <form onSubmit={handleSubmit} id="signUpForm" className="p-4 sm:p-0">
          <div className="grid gap-5 my-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="first-name"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                First Name
                <sup className="text-xs text-red-500">*</sup>
              </label>
              <input
                type="text"
                name="first_name"
                id="first-name"
                autoComplete="given-name"
                value={formData.first_name}
                onChange={handleChange}
                className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
                placeholder=""
                required
              />
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Last Name<sup className="text-xs text-red-500 ">*</sup>
              </label>
              <input
                type="text"
                name="last_name"
                id="last-name"
                value={formData.last_name}
                onChange={handleChange}
                autoComplete="family-name"
                className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
                placeholder=""
                required
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Your email<sup className="text-xs text-red-500 ">*</sup>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="username"
                className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
                placeholder="name@email.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Password<sup className="text-xs text-red-500 ">*</sup>
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="••••••••"
                  autoComplete="new-password"
                  className="flex bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
                  required={true}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5"
                  onClick={togglePasswordVisibility}
                >
                  {!showPassword ? (
                    <svg
                      className="w-4 h-4 text-gray-400 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 18"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-4 h-4 text-gray-400 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 14"
                    >
                      <g
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      >
                        <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                        <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
              <ul className="flex mt-2 text-xs">
                <li
                  className={
                    passwordMeetsLength
                      ? 'text-green-700 bg-green-100 dark:bg-green-300 dark:text-black mx-1 rounded p-1'
                      : formData.password
                        ? 'text-red-700 bg-red-100 dark:bg-red-300 dark:text-black mx-1 rounded p-1'
                        : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                  }
                >
                  Min. 8 Characters
                </li>
                <li
                  className={
                    passwordHasNumber
                      ? 'text-green-700 bg-green-100 mx-1 rounded p-1 dark:bg-green-300 dark:text-black'
                      : formData.password
                        ? 'text-red-700 bg-red-100 mx-1 rounded p-1 dark:bg-red-300 dark:text-black'
                        : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                  }
                >
                  1 Number
                </li>
                <li
                  className={
                    passwordHasUppercase
                      ? 'text-green-700 bg-green-100 mx-1 rounded p-1 dark:bg-green-300 dark:text-black'
                      : formData.password
                        ? 'text-red-700 bg-red-100 mx-1 rounded p-1 dark:bg-red-300 dark:text-black'
                        : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                  }
                >
                  1 Uppercase Letter
                </li>
              </ul>
              {/* {passwordError && (
                <div className='mt-2 text-xs text-red-500'>{passwordError}</div>
              )} */}
            </div>
            <div>
              <div className="relative">
                <label
                  htmlFor="confirm-password"
                  className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
                >
                  Confirm password
                  <sup className="text-xs text-red-500 ">*</sup>
                </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  // name='confirm-password'
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="••••••••"
                  autoComplete="new-password"
                  className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
                  required
                />{' '}
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 top-7"
                  onClick={togglePasswordVisibility}
                >
                  {!showPassword ? (
                    <svg
                      className="w-4 h-4 text-gray-400 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 18"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-4 h-4 text-gray-400 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 14"
                    >
                      <g
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      >
                        <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                        <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
              <div className="h-8 mt-2 w-96">
                {passwordError && (
                  <div className="w-full text-xs text-red-500">
                    {passwordError}
                  </div>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="business"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Company or Academic Institution
                <sup className="text-xs text-red-500 ">*</sup>
              </label>
              <input
                type="text"
                name="business"
                id="business"
                value={formData.details.business}
                onChange={handleChange}
                className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
                placeholder=""
                required
              />
            </div>

            <div className="flex flex-col mb-8 space-y-2">
              <div className="flex items-center space-x-3">
                <label
                  htmlFor="businessSwitch"
                  className="text-sm font-medium text-slate-900 dark:text-white"
                >
                  Are you a business?
                </label>

                <Switch
                  id="businessSwitch"
                  checked={showTax}
                  onChange={setShowTax}
                  className={`${
                    showTax ? 'bg-zinc-800' : 'bg-zinc-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
                >
                  <span className="sr-only">Are you a business?</span>
                  <span
                    className={`${
                      showTax ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200 ease-in-out`}
                  />
                </Switch>
              </div>

              <p className="text-sm font-light text-slate-500 dark:text-slate-300">
                If you are using the platform as a business entity, select this
                option to enter your tax ID number for optimal tax benefits. If
                you're only using the platform as an individual, you can leave
                this option unchecked.
              </p>
            </div>

            <div></div>

            <div className={`${showTax ? 'block' : 'hidden'}`}>
              <label
                htmlFor="taxIdType"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Tax ID Type
              </label>
              <select
                name="taxIdType"
                id="taxIdType"
                value={formData.details.tax_id_type}
                onChange={handleChange}
                className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
              >
                <option value="">Select Tax ID Type</option>
                <option value="ad_nrt">Andorra (NRT)</option>
                <option value="ae_trn">United Arab Emirates (TRN)</option>
                <option value="ar_cuit">Argentina (CUIT)</option>
                <option value="au_abn">Australia (ABN)</option>
                <option value="au_arn">Australia (ARN)</option>
                <option value="bg_uic">Bulgaria (UIC)</option>
                <option value="bh_vat">Bahrain (VAT)</option>
                <option value="bo_tin">Bolivia (TIN)</option>
                <option value="br_cnpj">Brazil (CNPJ)</option>
                <option value="br_cpf">Brazil (CPF)</option>
                <option value="ca_bn">Canada (BN)</option>
                <option value="ca_gst_hst">Canada (GST/HST)</option>
                <option value="ca_pst_bc">
                  Canada (PST, British Columbia)
                </option>
                <option value="ca_pst_mb">Canada (PST, Manitoba)</option>
                <option value="ca_pst_sk">Canada (PST, Saskatchewan)</option>
                <option value="ca_qst">Canada (QST)</option>
                <option value="ch_uid">Switzerland (UID)</option>
                <option value="ch_vat">Switzerland (VAT)</option>
                <option value="cl_tin">Chile (TIN)</option>
                <option value="cn_tin">China (TIN)</option>
                <option value="co_nit">Colombia (NIT)</option>
                <option value="cr_tin">Costa Rica (TIN)</option>
                <option value="de_stn">Germany (STN)</option>
                <option value="do_rcn">Dominican Republic (RCN)</option>
                <option value="ec_ruc">Ecuador (RUC)</option>
                <option value="eg_tin">Egypt (TIN)</option>
                <option value="es_cif">Spain (CIF)</option>
                <option value="eu_oss_vat">European Union (OSS VAT)</option>
                <option value="eu_vat">European Union (VAT)</option>

                <option value="ge_vat">Georgia (VAT)</option>
                <option value="hk_br">Hong Kong (BR)</option>
                <option value="hu_tin">Hungary (TIN)</option>
                <option value="id_npwp">Indonesia (NPWP)</option>
                <option value="il_vat">Israel (VAT)</option>
                <option value="in_gst">India (GST)</option>
                <option value="is_vat">Iceland (VAT)</option>
                <option value="jp_cn">Japan (CN)</option>
                <option value="jp_rn">Japan (RN)</option>
                <option value="jp_trn">Japan (TRN)</option>
                <option value="ke_pin">Kenya (PIN)</option>
                <option value="kr_brn">South Korea (BRN)</option>
                <option value="kz_bin">Kazakhstan (BIN)</option>
                <option value="li_uid">Liechtenstein (UID)</option>
                <option value="mx_rfc">Mexico (RFC)</option>
                <option value="my_frp">Malaysia (FRP)</option>
                <option value="my_itn">Malaysia (ITN)</option>
                <option value="my_sst">Malaysia (SST)</option>
                <option value="ng_tin">Nigeria (TIN)</option>
                <option value="no_vat">Norway (VAT)</option>
                <option value="no_voec">Norway (VOEC)</option>
                <option value="nz_gst">New Zealand (GST)</option>
                <option value="om_vat">Oman (VAT)</option>
                <option value="pe_ruc">Peru (RUC)</option>
                <option value="ph_tin">Philippines (TIN)</option>
                <option value="ro_tin">Romania (TIN)</option>
                <option value="rs_pib">Serbia (PIB)</option>
                <option value="ru_inn">Russia (INN)</option>
                <option value="ru_kpp">Russia (KPP)</option>
                <option value="sa_vat">Saudi Arabia (VAT)</option>
                <option value="sg_gst">Singapore (GST)</option>
                <option value="sg_uen">Singapore (UEN)</option>
                <option value="si_tin">Slovenia (TIN)</option>
                <option value="sv_nit">El Salvador (NIT)</option>
                <option value="th_vat">Thailand (VAT)</option>
                <option value="tr_tin">Turkey (TIN)</option>
                <option value="tw_vat">Taiwan (VAT)</option>
                <option value="ua_vat">Ukraine (VAT)</option>
                <option value="gb_vat">United Kingdom (VAT)</option>
                <option value="us_ein">United States (EIN)</option>
                <option value="uy_ruc">Uruguay (RUC)</option>
                <option value="ve_rif">Venezuela (RIF)</option>
                <option value="vn_tin">Vietnam (TIN)</option>
                <option value="za_vat">South Africa (VAT)</option>
              </select>
            </div>
            <div className={`${showTax ? 'block' : 'hidden'}`}>
              <label
                htmlFor="taxid"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Business Tax ID Number
              </label>
              <input
                type="text"
                name="taxId"
                id="taxId"
                value={formData.details.tax_id_number}
                onChange={handleChange}
                className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
                placeholder=""
                // required
              />
            </div>
          </div>
          {signUpError && (
            <div className="flex items-center w-full h-full mb-6 overflow-hidden text-red-500 sm:-mt-12">
              {errorMessage}
            </div>
          )}
          <div className="mb-6 space-y-3">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="terms"
                  name="agreeTerms"
                  aria-describedby="terms"
                  type="checkbox"
                  value={formData.agreeTerms}
                  onChange={handleChange}
                  className="w-4 h-4 border rounded border-slate-300 bg-slate-50 focus:ring-3 focus:ring-slate-300 dark:bg-slate-700 dark:border-slate-600 dark:focus:ring-slate-600 dark:ring-offset-slate-800 text-zinc-900"
                  required={true}
                />
              </div>

              <div className="ml-3 text-sm">
                <label
                  htmlFor="terms"
                  className="font-light text-slate-500 dark:text-slate-300"
                >
                  By signing up, you are creating a Tromero account, and you
                  agree to our{' '}
                  <Link
                    className="font-medium text-slate-600 dark:text-slate-500 hover:underline"
                    to="/terms"
                  >
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    className="font-medium text-slate-600 dark:text-slate-500 hover:underline"
                    to="/policy"
                  >
                    Privacy Policy
                  </Link>
                  .
                </label>
              </div>
            </div>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="newsletter"
                  name="subscribeNewsletter"
                  aria-describedby="newsletter"
                  type="checkbox"
                  checked={formData.subscribeNewsletter}
                  value={formData.subscribeNewsletter}
                  onChange={handleChange}
                  className="w-4 h-4 border rounded border-slate-300 bg-slate-50 focus:ring-3 focus:ring-slate-300 dark:bg-slate-700 dark:border-slate-600 dark:focus:ring-slate-600 dark:ring-offset-slate-800 text-zinc-900"
                  required=""
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="newsletter"
                  className="font-light text-slate-500 dark:text-slate-300"
                >
                  Email me about product updates and resources.
                </label>
              </div>
            </div>
          </div>
          <div className="flex space-x-3">
            <button
              // disabled={passwordError || formData.password !== confirmPassword}
              type="submit"
              // className='flex w-full text-white bg-slate-600 hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 sm:h-12 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800'
              className={`flex w-full text-white  font-medium rounded-md text-sm  text-center  justify-center items-center h-10 bg-zinc-900 hover:bg-zinc-800 dark:bg-gray-500 dark:hover:bg-slate-700
              }`}
            >
              {loading ? (
                <div
                  className="flex items-center justify-center w-full mx-auto spinner"
                  style={{
                    border: '4px solid rgba(255, 255, 255, 0.3)',
                    borderTop: '4px solid white',
                    borderRadius: '50%',
                    width: '24px',
                    height: '24px',
                    animation: 'spin 1s linear infinite',
                  }}
                ></div>
              ) : (
                <span className="flex items-center justify-center w-full my-auto">
                  Request Access
                </span>
              )}{' '}
            </button>
          </div>
          {/* ----------continue with google--------- */}
          <div className="hidden mt-10">
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="px-6 text-gray-900 bg-white">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-6">
              <a
                href="#"
                className="flex items-center justify-center w-full gap-3 px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
              >
                <svg className="w-5 h-5" aria-hidden="true" viewBox="0 0 24 24">
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
                <span className="text-sm font-semibold leading-6">Google</span>
              </a>
            </div>
          </div>
        </form>
      </div>
      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          onClose={handleSuccessModalClose}
        />
      )}
    </div>
  );
};

export default SignUpStep1;
