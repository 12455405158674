import React from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { getLogMessage } from '../../../utils/TailorUtils';
import { modelStateMessage } from './modelStateConstants';

const ModelCard = ({
  model,
  handleSetSelectedModel,
  toggleDeployStatus,
  selfHostedServers,
  isSelected,
  index,
}) => {
  // ... (copy the modelStateMessage object from the original ModelList component)

  const findSelfHostedServerName = (id) => {
    const server = selfHostedServers.find((server) => server.id === id);
    return server ? server.server_name : 'Tromero';
  };

  return (
    <div
      aria-roledescription="button"
      // aria-roledescription="button"
      key={model.model_id + ' ' + index}
      className={clsx(
        'flex items-start justify-between w-full p-4 m-1 border rounded-md border-zinc-300 relative min-h-32 ',
        isSelected ? 'bg-zinc-100 ring-2 ring-indigo-400 ' : 'bg-white',
      )}
      onClick={() => handleSetSelectedModel(model)}
    >
      <div className="grid w-full grid-cols-6 ">
        {model.self_hosted &&
          (model.state === 'deployed' || model.state === 'failed_undeploy') && (
            <div className="absolute px-2 py-1 text-xs bg-blue-200 rounded-md bottom-1 left-1">
              Self Hosted:{' '}
              {findSelfHostedServerName(model.self_hosted_server_id)}
            </div>
          )}
        <div className="self-start max-w-full col-span-2 text-lg font-medium text-left break-words text-zinc-800 text-wrap md:self-center mb-10">
          <div className="w-full overflow-hidden whitespace-nowrap text-ellipsis">
            {model.model_name}
          </div>
          <div className="mt-1 text-sm font-light">
            Base Model: <span>{model?.base_model_data?.model_name}</span>
          </div>
          <div className="text-sm font-light">
            Training Data: {getLogMessage(model)}
          </div>
        </div>
        <div className="flex items-start text-sm text-zinc-600 justify-self-center">
          {new Date(model.created_at).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </div>
        <div className="flex items-start text-sm text-zinc-600 justify-self-center">
          {model.last_deployed_on
            ? new Date(model.last_deployed_on).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
            : null}
        </div>
        <div className="flex items-start text-sm text-zinc-600 justify-self-center">
          {model.last_used
            ? new Date(model.last_used).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
            : null}
        </div>
        <div className="flex items-end pb-12 text-sm text-zinc-600 justify-self-end ">
          <Switch.Group as="div" className="flex items-center justify-between">
            <span className="absolute flex flex-col flex-grow text-right text-wrap bottom-4 right-4">
              <Switch.Label
                as="span"
                className="text-sm font-medium leading-6 text-right text-gray-900"
                passive
              >
                {modelStateMessage[model.state]?.switchLabel}
              </Switch.Label>
              <Switch.Description
                as="span"
                className="text-sm text-left text-gray-500 "
              >
                {modelStateMessage[model.state]?.switchDescription}
              </Switch.Description>
            </span>
            <Switch
              checked={
                model.state === 'deployed' || model.state === 'failed_undeploy'
              }
              onClick={(e) => toggleDeployStatus(e, model)}
              disabled={
                model.state === 'deploying' || model.state === 'undeploying'
              }
              className={clsx(
                model.state === 'deployed' ? 'bg-zinc-800' : 'bg-gray-400',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-zinc-800 focus:ring-offset-2  disabled:cursor-default disabled:border disabled:border-gray-200',
                model.state === 'failed_undeploy'
                  ? 'disabled:bg-zinc-300'
                  : 'disabled:bg-gray-100',
              )}
            >
              <span
                aria-hidden="true"
                className={clsx(
                  model.state === 'deployed' ||
                    model.state === 'failed_undeploy'
                    ? 'translate-x-5'
                    : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out shadow-zinc-400',
                )}
              />
            </Switch>
          </Switch.Group>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ModelCard);
