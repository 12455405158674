import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { motion } from 'framer-motion';

import { useCurrencyConverter } from '../../Hooks/useCurrencyConverter';

import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import '/node_modules/flag-icons/css/flag-icons.min.css';
import { FaStripe } from 'react-icons/fa';

import Spinner from '../Spinner';
import { useUser } from '../../UserContext';
import { amountToBackend, amountToFrontend } from '../../utils/CurrencyUtils';

const TopupModal = ({ open, setOpen }) => {
  const {
    user,
    customAxios,
    refresh,
    setRefresh,
    setUser,
    fetchAndUpdateUserDetails,
  } = useUser();
  const money = useCurrencyConverter();
  const [updatedData, setUpdatedData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    credits: '',
  });
  const [isExpanded, setIsExpanded] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const [threshold, setThreshold] = useState(0);
  const [topup, setTopup] = useState(0);
  const [enabled, setEnabled] = useState(true);
  const [quickConfirm, setQuickConfirm] = useState(false);
  const [manualConfirm, setManualConfirm] = useState(false);
  const [selectedTopupAmount, setSelectedTopupAmount] = useState(0);
  const [manualTopupAmount, setManualTopupAmount] = useState(0);
  const [loadingAddCard, setLoadingAddCard] = useState(false);

  const stripe_publishable_key = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(stripe_publishable_key);

  const handleTopupChange = (event) => {
    setTopup(event.target.value);
  };

  const handleThresholdChange = (event) => {
    setThreshold(event.target.value);
  };

  useEffect(() => {
    // Automatically determine if auto-topup should be considered enabled based on threshold and topup
    if (user) {
      const autoTopUpEnabled =
        user.new_credits_top_up_threshold > 0 &&
        user.new_credits_top_up_amount > 0;
      setEnabled(autoTopUpEnabled);
      setThreshold(amountToFrontend(user.new_credits_top_up_threshold)); // Convert cents to dollars
      setTopup(amountToFrontend(user.new_credits_top_up_amount)); // Convert cents to dollars
    }
  }, [user]);

  // Handlers for changing the state
  const handleEnabledChange = async (newEnabledState) => {
    // Directly update enabled state
    setEnabled(newEnabledState);

    if (newEnabledState) {
      // Check if there are payment methods before enabling
      if (user.payment_methods.length === 0) {
        // Inform user to add a payment method first and do not enable auto top-up
        toast.error('Please add a payment method first.');
        setEnabled(false); // Make sure switch goes back to disabled
        return;
      } else {
        // Apply default values if current values are not above zero or keep existing values
        const defaultThreshold = threshold > 0 ? threshold : 10;
        const defaultTopup = topup > 0 ? topup : 20;
        handleSubmitSettings(defaultThreshold, defaultTopup);
      }
    } else {
      // Set values to zero to disable auto top-up
      setThreshold(0);
      setTopup(0);
      handleSubmitSettings(0, 0); // Submit zero values to server
    }
  };

  function handleCreditInputChange(event) {
    const value = event.target.value;
    const numValue = parseFloat(value); // Converts the input string to a number

    if (!isNaN(numValue) && numValue >= 5) {
      setManualTopupAmount(numValue);
      event.target.setCustomValidity(''); // Clear validation message
    } else {
      setManualTopupAmount(0); // Reset or handle appropriately
      event.target.setCustomValidity('Please enter a value of at least $5');
    }
  }

  const handleSubmitSettings = async (newThreshold, newTopup) => {
    // Convert string inputs to numbers for accurate comparison
    const thresholdValue = parseFloat(newThreshold);
    const topupValue = parseFloat(newTopup);

    // Check if either value is less than $5 and not empty
    if (
      (thresholdValue > 0 && thresholdValue < 5) ||
      (topupValue > 0 && topupValue < 5)
    ) {
      toast.error('Minimum threshold and top-up amount is $5.');
      return;
    }

    const payload = {
      top_up_threshold: amountToBackend(newThreshold), // Convert dollars to cents
      top_up_amount: amountToBackend(newTopup),
    };

    try {
      await customAxios.put('/credits/preferences', payload);
      setUser((prevUser) => ({
        ...prevUser,
        new_credits_top_up_threshold: payload.top_up_threshold,
        new_credits_top_up_amount: payload.top_up_amount,
      }));
      await fetchAndUpdateUserDetails();
      toast.success(
        newThreshold > 0
          ? 'Auto top-up settings updated successfully'
          : 'Auto top-up disabled',
      );
      if (newThreshold === 0 && newTopup === 0) {
        setTimeout(() => {
          setOpen(false);
        }, 500);
      }
    } catch (error) {
      console.error('Error updating auto top-up settings:', error);
      toast.error('Failed to update settings');
    }
  };

  const handleManualTopUp = async (amount) => {
    if (parseInt(amount) < 5) {
      toast.error('Minimum top-up amount is $5.');
      return;
    }

    const payload = {
      amount: amountToBackend(parseInt(amount)),
    };

    try {
      await customAxios.post('credits', payload);
      toast.success(`$${amount} added to your balance`);
      setUser({
        ...user,
        credits: user.new_credits_available + payload.amount,
      });

      await fetchAndUpdateUserDetails();
      setQuickConfirm(false);
      setManualConfirm(false);
    } catch (error) {
      console.error('Error during manual top-up:', error);
      toast.error('Failed to add credits');
    }
  };

  const handleContinue = () => {
    setLoadingAddCard(true);
    handleCheckout();
  };

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    try {
      const response = await customAxios.post('create-session', {});

      await fetchAndUpdateUserDetails();
      const session = response.data;
      setLoadingAddCard(false);
      const result = await stripe.redirectToCheckout({
        sessionId: session.session_id,
      });
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
      if (error.code === 'no-valid-token') {
        return;
      }
      toast.error('Error during checkout');
    } finally {
      // setLoadingAddCard(false);
    }
  };

  const handleQuickClick = (amount) => {
    setSelectedTopupAmount(amount);
    setQuickConfirm(true);
    setManualConfirm(false);
  };

  const handleManualClick = (amount) => {
    setSelectedTopupAmount(amount);
    setQuickConfirm(false);
    setManualConfirm(true);
  };

  const handleSubmitAndClose = (threshold, topup) => {
    handleSubmitSettings(threshold, topup);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left text-gray-700 transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <button
                  onClick={() => setOpen(false)}
                  className="fixed right-2 top-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div className="flex items-center justify-between border-b">
                  <div className="pb-2 font-normal text-gray-700">
                    Current Balance:{' '}
                    <span className="font-semibold text-gray-900 ">
                      {typeof user.new_credits_available === 'number'
                        ? money(user.new_credits_available)
                        : money(0)}
                    </span>
                  </div>
                  <div></div>
                </div>
                {user?.payment_methods.length > 0 ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="flex flex-col pt-8 h-30"
                    >
                      <span className="mb-2 font-semibold text-gray-700">
                        Quick top-up
                      </span>
                      <div className="flex w-full h-12 space-x-4 text-gray-800 justify-evenly">
                        <button
                          onClick={() => handleQuickClick(10)}
                          className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
                        >
                          10$
                        </button>
                        <button
                          onClick={() => handleQuickClick(25)}
                          className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
                        >
                          25$
                        </button>
                        <button
                          onClick={() => handleQuickClick(100)}
                          className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
                        >
                          100$
                        </button>
                      </div>
                      {quickConfirm && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="flex items-center pt-8 h-30"
                        >
                          <button
                            className="h-6 px-2 mr-2 border rounded hover:border-gray-900"
                            onClick={() => setQuickConfirm(false)}
                          >
                            -
                          </button>{' '}
                          {user.payment_methods.length > 0 ? (
                            <div className="flex items-center justify-between w-full pr-2 sm:px-4">
                              <div className="relative flex flex-col justify-center w-40 h-16 px-2 border border-gray-900 rounded sm:w-48">
                                <div className="absolute flex items-center justify-center w-3 h-3 bg-gray-900 rounded-full top-1 right-1">
                                  <div className="w-1 h-1 bg-white rounded-full"></div>
                                </div>
                                <div className="text-gray-700">
                                  Payment Method
                                </div>
                                <div className="mt-1 text-xs text-gray-500">
                                  <span className="capitalize">
                                    {user.payment_methods[0].brand}
                                  </span>
                                  ****
                                  {user.payment_methods[0].last4}
                                </div>
                              </div>
                              <button
                                onClick={() =>
                                  handleManualTopUp(selectedTopupAmount)
                                }
                                className="flex items-center justify-center w-24 h-10 px-2 text-sm text-white bg-indigo-600 border rounded shadow hover:bg-indigo-700"
                              >
                                Pay ${selectedTopupAmount}
                              </button>
                            </div>
                          ) : (
                            <div className="flex items-center justify-between w-full ">
                              <div className="flex-1 ml-2 text-sm text-gray-700">
                                No payment methods available.
                              </div>
                              <button
                                onClick={handleContinue}
                                className="flex items-center h-10 px-4 text-sm border border-gray-900 rounded shadow hover:bg-gray-100"
                              >
                                Add Payment Method
                              </button>
                            </div>
                          )}
                        </motion.div>
                      )}

                      <div>
                        <div className="mt-6 mb-2 font-semibold text-gray-700">
                          Top-up amount{' '}
                          <span className="text-xs text-gray-500">
                            ($5 Minimum)
                          </span>
                        </div>
                        <div className="flex mt-2 rounded-md shadow-sm">
                          <div className="relative flex items-stretch flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-800 pointer-events-none">
                              {/* <UsersIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        /> */}
                              $
                            </div>
                            <input
                              type="text"
                              name="credit"
                              id="credit"
                              className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 w-1/2"
                              placeholder="Enter amount"
                              onChange={handleCreditInputChange}
                              pattern="^[5-9]$|^[1-9]\d+$"
                            />
                          </div>
                          <button
                            // onClick={() => {
                            //   const amount =
                            //     document.getElementById('credit').value;
                            //   handleManualTopUp(amount * 100);
                            // }}
                            onClick={() => handleManualClick(manualTopupAmount)}
                            type="button"
                            disabled={!manualTopupAmount}
                            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-100 ring-1 ring-inset ring-gray-900 hover:text-gray-900 bg-gray-900 hover:bg-gray-100 whitespace-nowrap"
                          >
                            + Add credit
                          </button>
                        </div>
                      </div>
                    </motion.div>
                    {manualConfirm && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="flex items-center pt-8 h-30"
                      >
                        <button
                          className="h-6 px-2 mr-2 border rounded hover:border-gray-900"
                          onClick={() => setManualConfirm(false)}
                        >
                          -
                        </button>{' '}
                        {user.payment_methods.length > 0 ? (
                          <div className="flex items-center justify-between w-full pr-2 sm:px-4">
                            <div className="relative flex flex-col justify-center w-40 h-16 px-2 border border-gray-900 rounded sm:w-48">
                              <div className="absolute flex items-center justify-center w-3 h-3 bg-gray-900 rounded-full top-1 right-1">
                                <div className="w-1 h-1 bg-white rounded-full"></div>
                              </div>
                              <div className="text-gray-700">
                                Payment Method
                              </div>
                              <div className="mt-1 text-xs text-gray-500">
                                <span className="capitalize">
                                  {user.payment_methods[0].brand}
                                </span>
                                ****
                                {user.payment_methods[0].last4}
                              </div>
                            </div>
                            <button
                              onClick={() =>
                                handleManualTopUp(selectedTopupAmount)
                              }
                              className="flex items-center justify-center w-24 h-10 px-2 text-sm text-white bg-indigo-600 border rounded shadow hover:bg-indigo-700"
                            >
                              Pay ${selectedTopupAmount}
                            </button>
                          </div>
                        ) : (
                          <div className="flex items-center justify-between w-full ">
                            <div className="flex-1 ml-2 text-sm text-gray-700">
                              No payment methods available.
                            </div>
                            <button
                              onClick={handleContinue}
                              className="flex items-center h-10 px-4 text-sm border border-gray-900 rounded shadow hover:bg-gray-100"
                            >
                              Add Payment Method
                            </button>
                          </div>
                        )}
                      </motion.div>
                    )}
                    <div className="flex-1 py-10 bg-white rounded-md dark:bg-gray-800">
                      <Switch.Group
                        as="div"
                        className="flex items-center justify-between"
                      >
                        <span className="flex flex-col flex-grow">
                          <Switch.Label
                            as="span"
                            className="text-lg font-semibold text-gray-900 dark:text-white"
                            passive
                          >
                            <span className="flex items-center text-lg font-semibold text-gray-900 dark:text-white">
                              Auto top-up is {enabled ? 'enabled' : 'disabled'}
                              {enabled ? (
                                <span></span>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 16 16"
                                  fill="currentColor"
                                  className="w-4 h-4 ml-2 text-yellow-300"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M6.701 2.25c.577-1 2.02-1 2.598 0l5.196 9a1.5 1.5 0 0 1-1.299 2.25H2.804a1.5 1.5 0 0 1-1.3-2.25l5.197-9ZM8 4a.75.75 0 0 1 .75.75v3a.75.75 0 1 1-1.5 0v-3A.75.75 0 0 1 8 4Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </span>
                          </Switch.Label>
                          <Switch.Description
                            as="div"
                            className="pr-4 text-sm text-gray-500 "
                          >
                            <span className="text-pretty">
                              {enabled && user.payment_methods.length > 0 ? (
                                `Your balance will Auto-top-up with $${amountToFrontend(
                                  user?.new_credits_top_up_amount,
                                ).toFixed(
                                  2,
                                )} when it falls below $${amountToFrontend(
                                  user?.new_credits_top_up_threshold,
                                ).toFixed(2)} using payment method ${
                                  user.payment_methods[0].brand
                                    .charAt(0)
                                    .toUpperCase() +
                                  user.payment_methods[0].brand.slice(1)
                                } (${user.payment_methods[0].last4}). VAT will also be added to this Auto-top-up.`
                              ) : user.payment_methods.length === 0 ? (
                                <span>
                                  You will need to first add a payment method in{' '}
                                  <Link
                                    className="text-indigo-600"
                                    to="/settings"
                                  >
                                    Settings
                                  </Link>{' '}
                                  to enable Auto top-up.
                                </span>
                              ) : (
                                'We recommend enabling auto top-up to avoid service interruptions.'
                              )}
                            </span>
                          </Switch.Description>
                        </span>
                        <Switch
                          checked={enabled}
                          disabled={user && user.payment_methods.length === 0}
                          onChange={handleEnabledChange}
                          className={classNames(
                            enabled ? 'bg-gray-900' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2',
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            className={classNames(
                              enabled ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                            )}
                          >
                            <span
                              className={classNames(
                                enabled
                                  ? 'opacity-0 duration-100 ease-out'
                                  : 'opacity-100 duration-200 ease-in',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                              )}
                              aria-hidden="true"
                            >
                              <svg
                                className="w-3 h-3 text-gray-400"
                                fill="none"
                                viewBox="0 0 12 12"
                              >
                                <path
                                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <span
                              className={classNames(
                                enabled
                                  ? 'opacity-100 duration-200 ease-in'
                                  : 'opacity-0 duration-100 ease-out',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                              )}
                              aria-hidden="true"
                            >
                              <svg
                                className="w-3 h-3 text-indigo-600"
                                fill="currentColor"
                                viewBox="0 0 12 12"
                              >
                                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                              </svg>
                            </span>
                          </span>
                        </Switch>
                      </Switch.Group>
                      <div className="mt-8 text-sm text-gray-500">
                        The prices displayed for the credit purchases as well as
                        the auto top-up amounts do not include VAT. VAT will be
                        added at checkout by Stripe based on the VAT rate in
                        your location. The final price you pay will be the price
                        for the credits as well as the VAT.
                      </div>
                    </div>
                    {enabled && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                      >
                        <div className="pt-10 border-t">
                          <label className="text-base font-semibold text-gray-900">
                            Top-up threshold
                          </label>
                          <p className="text-sm text-gray-500">
                            The low limit at which your balance will be topped
                            up
                          </p>
                          <fieldset className="mt-4">
                            <legend className="sr-only">
                              Threshold method
                            </legend>

                            <div className="flex w-full pb-6 mt-2 rounded-md ">
                              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-800 pointer-events-none">
                                  {/* <UsersIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        /> */}
                                  $
                                </div>
                                <input
                                  type="text"
                                  name="threshold"
                                  value={threshold}
                                  className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-1/2"
                                  placeholder="Minimum balance before top-up"
                                  onChange={handleThresholdChange}
                                  pattern="^[5-9]$|^[1-9]\d+$"
                                />
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="mt-6">
                          <label className="text-base font-semibold text-gray-900">
                            Top-up amount
                          </label>
                          <p className="text-sm text-gray-500">
                            The amount your balance will automatically be topped
                            up by
                          </p>
                          <fieldset className="mt-4">
                            <legend className="sr-only">Top-up method</legend>

                            <div className="flex w-full pb-6 mt-2 rounded-md ">
                              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-800 pointer-events-none">
                                  {/* <UsersIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        /> */}
                                  $
                                </div>
                                <input
                                  type="text"
                                  name="topup"
                                  value={topup}
                                  className="block w-full  rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-1/2"
                                  placeholder="Enter amount"
                                  onChange={handleTopupChange}
                                  pattern="^[5-9]$|^[1-9]\d+$"
                                />
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </motion.div>
                    )}
                    {enabled && (
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-gray-900 rounded-md shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => handleSubmitAndClose(threshold, topup)}
                      >
                        Update Auto-top-up
                      </button>
                    )}
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center h-60">
                    <div className="text-xl font-semibold text-gray-900">
                      No payment methods available
                    </div>
                    <div className="mt-4 text-sm text-center text-gray-500">
                      You need to add a payment method to manage your balance.
                    </div>
                    <button
                      className="w-1/4 h-12 mt-8 rounded-md  min-w-[200px] items-center flex justify-center group  shadow bg-gray-900 mb-6 hover:bg-gray-800"
                      onClick={handleContinue}
                    >
                      {loadingAddCard ? (
                        <Spinner />
                      ) : (
                        <div className="flex items-center justify-center my-auto">
                          <div className="flex text-sm font-medium text-gray-100 ">
                            + Add a card with{' '}
                          </div>
                          <div>
                            <FaStripe className="w-12 h-12 ml-1.5 text-gray-100" />
                          </div>
                        </div>
                      )}
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TopupModal;
