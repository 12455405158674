import React, { useState } from 'react';
import { Modal } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { useUser } from '../../UserContext';
import Spinner from '../Spinner';

const MissingSSHKeyModal = ({
  showModal,
  closeModal,
  onSSHKeyUploadSuccess = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const { customAxios, fetchAndUpdateUserDetails } = useUser();

  const handleAddSSHKey = async (e) => {
    e.preventDefault();
    setLoading(true);
    const sshKeyData = {
      public_key: publicKey,
    };

    try {
      const response = await customAxios.post('ssh_keys', sshKeyData);

      if (response.status === 201) {
        toast.success('Public key added successfully');
        setPublicKey('');
        await fetchAndUpdateUserDetails();
      }

      // console.log('SSH Key added:', response.data);
      if (onSSHKeyUploadSuccess) {
        await onSSHKeyUploadSuccess();
      }
      closeModal();
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
      console.error('Error adding SSH Key:', error);
      toast.error('Error adding SSH Key');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal show={showModal} onClose={closeModal}>
      <Modal.Header> Reminder</Modal.Header>
      <Modal.Body>
        {' '}
        <div className="font-spaceG dark:text-gray-100">
          Before renting an instance, please upload an SSH key. This precaution
          helps prevent unnecessary expenses due to instances running without
          access.{' '}
          <a
            href="https://tromero.ai/articles/3"
            target="_blank"
            className="text-indigo-500 hover:text-indigo-600"
          >
            Learn more
          </a>
        </div>
        <div className="mt-4 font-spaceG dark:text-gray-100">
          You can add or modify your keys in{' '}
          <Link
            to="/settings"
            className="text-indigo-500 hover:text-indigo-600"
          >
            Settings
          </Link>{' '}
          or upload a key below.
        </div>
        <form onSubmit={handleAddSSHKey}>
          <div className="grid gap-5 my-6 sm:grid-cols-2">
            <div className="sm:col-span-2 font-spaceG">
              <label
                htmlFor="public-key"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                {/* Public Key */}
              </label>
              <textarea
                name="public-key"
                id="public-key"
                placeholder="Paste your SSH public key here"
                value={publicKey}
                onChange={(e) => setPublicKey(e.target.value.trim())}
                className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-gray-700 dark:border-slate-600 dark:placeholder-gray-400 dark:text-white"
                rows="4"
                required
              ></textarea>
            </div>
          </div>

          <div className="flex space-x-3">
            <button
              type="submit"
              className="flex items-center justify-center w-1/4 min-w-[200px] h-12 px-5 text-sm font-medium text-center text-white bg-gray-900 rounded-md hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 whitespace-nowrap"
            >
              {loading ? <Spinner /> : <span>Upload SSH Key</span>}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default MissingSSHKeyModal;
