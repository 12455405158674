import { Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import LiveInstancesListItem from './LiveInstancesListItem';
import ActionModal from '../Modals/ActionModal';
import { useUser } from '../../UserContext';
import OobaboogaModal from '../Modals/OobaboogaModal';
import JupyterModal from '../Modals/JupyterModal';
import KeyCopyModal from '../Modals/KeyCopyModal';
import PortMappingModal from '../Modals/PortMappingModal';

const LiveInstancesList = ({ liveInstances }) => {
  const [showActionModal, setShowActionModal] = useState(false);
  const [showOobaboogaModal, setShowOobaboogaModal] = useState(false);
  const [showKeyCopyModal, setShowKeyCopyModal] = useState(false);
  const [sshKey, setSshKey] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [showJupyterModal, setShowJupyterModal] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [showPortMappingModal, setShowPortMappingModal] = useState(false);
  const { customAxios, setRefresh } = useUser();

  const endInstance = async (selectedInstanceId) => {
    try {
      await customAxios.post(`pods/${selectedInstanceId}/end`, {});
      setRefresh(true);
      toast.success('Instance ended successfully.');
    } catch (error) {
      console.error('Error ending Instance: ', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
      toast.error('There was an error ending the instance.');
    }
  };

  const handleEndInstanceButton = (instance) => {
    setSelectedInstance(instance);
    setShowActionModal(true);
  };

  const handleOobaboogaButton = (instance) => {
    setSelectedInstance(instance);
    setShowOobaboogaModal(true);
  };

  const handleJupyterButton = (instance) => {
    setSelectedInstance(instance);
    setShowJupyterModal(true);
  };

  const handleSSHButton = (instance) => {
    (instance.os_template = instance?.os_template?.includes(':')
      ? instance.os_template.split(':')[1]
      : instance.os_template),
      setSelectedInstance(instance);
    const port = instance?.ports?.find((port) => port.name === 'ssh-port');
    if (port) {
      const sshKeyString = `ssh -p ${port.node_port} tromero_client@${instance.ip_address}`;
      setSshKey(sshKeyString);
      setIpAddress('');
    } else {
      setIpAddress(instance.ip_address);
      setSshKey('');
    }
    setShowKeyCopyModal(true);
  };

  const handlePortMappingButton = (instance) => {
    setSelectedInstance(instance);
    setShowPortMappingModal(true);
  };

  return (
    <>
      <ActionModal
        showModal={showActionModal}
        onConfirm={() => endInstance(selectedInstance?.id)}
        closeModal={() => setShowActionModal(false)}
      />
      <KeyCopyModal
        sshKey={sshKey}
        ipAddress={ipAddress}
        showKeyCopyModal={showKeyCopyModal}
        onClose={() => setShowKeyCopyModal(false)}
        osTemplate={selectedInstance?.os_template}
      />
      <JupyterModal
        jupyter_url={`http://${selectedInstance?.ip_address}:${selectedInstance?.ports?.find((i) => i.name === 'jupyter-port')?.node_port}`}
        jupyter_token={selectedInstance?.token}
        showJupyterModal={showJupyterModal}
        onClose={() => setShowJupyterModal(false)}
      />

      <OobaboogaModal
        showModal={showOobaboogaModal}
        closeModal={() => setShowOobaboogaModal(false)}
        password={selectedInstance?.token}
        redirectUrl={selectedInstance?.public_url}
      />
      <PortMappingModal
        showModal={showPortMappingModal}
        closeModal={() => setShowPortMappingModal(false)}
        ports={selectedInstance?.ports}
      />

      <div className="overflow-x-auto max-h-[240px]">
        <table className="w-full text-sm text-left text-gray-500 font-dmSans dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-[12px] "></th>
              <th
                scope="col"
                className="w-1/12 px-2 py-3 min-w-fit text-nowrap"
              >
                Instance ID
              </th>
              <th
                scope="col"
                className="w-2/12 px-2 py-3 min-w-fit text-nowrap"
              >
                GPU Type
              </th>
              <th scope="col" className="w-1/6 px-2 py-3 min-w-fit text-nowrap">
                Template
              </th>
              <th scope="col" className="w-1/6 px-2 py-3 min-w-fit text-nowrap">
                Created at
              </th>
              <th scope="col" className="w-1/6 px-2 py-3 min-w-fit text-nowrap">
                Duration
              </th>
              <th
                scope="col"
                className=" w-[14%] px-2 py-3 min-w-fit text-nowrap"
              >
                Cost
              </th>
              <th
                scope="col"
                className="w-1/6 px-2 py-3 mr-4 min-w-fit text-nowrap"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {liveInstances?.map((instance) => {
              if (
                instance.os_template.split(':')[1] === 'train_mistral' ||
                instance.os_template.split(':')[1] === 'eval_llm' ||
                instance.os_template.split(':')[1] === 'train_mistral2'
              ) {
                return null;
              }
              return (
                <Fragment key={instance.id}>
                  <LiveInstancesListItem
                    instance={instance}
                    handleEndInstanceButton={handleEndInstanceButton}
                    handleOobaboogaButton={handleOobaboogaButton}
                    handleJupyterButton={handleJupyterButton}
                    handleSSHButton={handleSSHButton}
                    handlePortMappingButton={handlePortMappingButton}
                  />
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LiveInstancesList;
