import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useUser } from '../../UserContext';
import CurrencySelector from '../CurrencySelector';
import Spinner from '../Spinner';

const ProfileContent = () => {
  const { customAxios, user, setUser } = useUser();
  const [passwordMeetsLength, setPasswordMeetsLength] = useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordHasUppercase, setPasswordHasUppercase] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [showPasswordMessage, setShowPasswordMessage] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState('');

  const [updatedData, setUpdatedData] = useState({
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    email: user?.email || '',
    currency: user?.currency || '',
    credits: user?.credits || 0,
  });

  useEffect(() => {
    if (user) {
      setUpdatedData({
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        email: user.email || '',
        currency: user.currency || '',
      });
    }
  }, [user]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Individual submit handlers for each field
  const handleUpdateFirstName = async (e) => {
    e.preventDefault();
    const updatedValue = e.target.elements.firstName.value;
    await updateField('first_name', updatedValue);
  };

  const handleUpdateLastName = async (e) => {
    e.preventDefault();
    const updatedValue = e.target.elements.lastName.value;
    await updateField('last_name', updatedValue);
  };

  // modifying email not allowed?
  //   const handleUpdateEmail = async (e) => {
  //     e.preventDefault();
  //     const updatedValue = e.target.elements.email.value;
  //     await updateField('email', updatedValue);
  //   };

  const updateField = async (fieldName, value) => {
    const payload = {
      field: fieldName,
      value: value,
    };

    try {
      await customAxios.post('update_profile', payload);

      // TODO ** the API call should return the updated user details
      setUser({ ...user, [fieldName]: value }); // this or from the API call response

      toast.success(`User details updated`);
    } catch (error) {
      console.error(`Error updating ${fieldName}:`, error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
      toast.error(`Error updating ${fieldName}`);
    }
  };

  const handleUpdateCurrency = (e) => {
    e.preventDefault();
  };

  const handleNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    updatePasswordCriteria(newPassword);
  };

  const updatePasswordCriteria = (password) => {
    setPasswordMeetsLength(password.length >= 8);
    setPasswordHasNumber(/\d/.test(password));
    setPasswordHasUppercase(/[A-Z]/.test(password));
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();

    // Extract the values from the form fields
    const currentPassword = event.target['current-password'].value;
    const newPassword = event.target['new-password'].value;
    const confirmNewPassword = event.target['confirm-new-password'].value;

    updatePasswordCriteria(newPassword);

    // Validate the new password
    if (newPassword !== confirmNewPassword) {
      setPasswordMessage("New passwords don't match.");
      setShowPasswordMessage(true);
      setTimeout(() => {
        setShowPasswordMessage(false);
      }, 5000);
      return; // Exit the function early if passwords don't match
    }

    // Validate the password format
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setPasswordMessage('Password does not meet requirements');
      setShowPasswordMessage(true);
      setTimeout(() => {
        setShowPasswordMessage(false);
      }, 5000);
      return;
    }

    // Create the payload
    const payload = {
      current_password: currentPassword,
      new_password: newPassword,
    };

    try {
      setLoadingChangePassword(true); // Make the POST request to the /change-password endpoint
      await customAxios.post('change-password', payload);

      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setPasswordMeetsLength(false);
      setPasswordHasNumber(false);
      setPasswordHasUppercase(false);
      toast.success('Password changed successfully');
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error changing password:', error.message);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
      toast.error('Error changing password');
    } finally {
      setLoadingChangePassword(false);
    }
  };
  // Assume you have forms and inputs here for updating the profile
  return (
    <div className="max-w-2xl mx-auto space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div className="flex-col justify-center flex-1 p-6 mx-auto border rounded-lg rightside ">
        <h2 className="mb-4 text-xl font-medium leading-tight tracking-tight text-slate-900 sm:mb-6 dark:text-white">
          Update Profile Information
        </h2>

        <div>
          {/* Update First Name */}
          <label
            htmlFor="first-name"
            className="block my-2 text-sm font-medium text-slate-900 dark:text-white"
          >
            First Name
          </label>
          <form
            onSubmit={handleUpdateFirstName}
            className="relative flex items-center"
          >
            <input
              type="text"
              name="firstName"
              id="first-name"
              value={updatedData.firstName}
              onChange={handleInputChange}
              className="flex-1 block w-full border-gray-300 shadow-sm rounded-l-md form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300"
              required
            />
            <button
              type="submit"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-[11px] text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              {' '}
              Save
            </button>
          </form>
        </div>

        <div>
          {/* Update Last Name */}
          <label
            htmlFor="last-name"
            className="block my-2 text-sm font-medium text-slate-900 dark:text-white"
          >
            Last Name
          </label>
          <form
            onSubmit={handleUpdateLastName}
            className="relative flex items-center"
          >
            <input
              type="text"
              name="lastName"
              id="last-name"
              value={updatedData.lastName}
              onChange={handleInputChange}
              className="flex-1 block w-full border-gray-300 shadow-sm rounded-l-md form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300"
              required
            />
            <button
              type="submit"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-[11px] text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Save
            </button>
          </form>
        </div>

        <div>
          {/* Update Email */}
          <label
            htmlFor="email"
            className="block my-2 text-sm font-medium text-slate-900 dark:text-white"
          >
            Your email
          </label>
          <form
            // onSubmit={handleUpdateEmail}
            onSubmit={() => {}}
            className="relative flex items-center"
          >
            <input
              type="email"
              name="email"
              id="email"
              readOnly
              value={
                updatedData.email === 'bobbobingtonabc@gmail.com'
                  ? 'Bob@gmail.com'
                  : updatedData.email
              }
              onChange={handleInputChange}
              className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300"
              required
            />
            <button
              type="submit"
              disabled
              className="absolute px-4 mb-2 font-medium text-white rounded-md right-1 inset-y-1 bg-slate-600 dark:bg-gray-900 hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-slate-300 disabled:hidden"
            >
              Save
            </button>
          </form>
        </div>

        <div>
          {/* Update preferred currency */}
          <label
            htmlFor="last-name"
            className="block my-2 text-sm font-medium text-slate-900 dark:text-white"
          >
            Preferred Display Currency
          </label>
          <form
            onSubmit={handleUpdateCurrency}
            className="relative flex items-center"
          >
            <CurrencySelector grow />

            {/* <button
              type="submit"
              className="absolute px-4 mb-2 font-medium text-white rounded-md right-1 inset-y-1 bg-slate-600 hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-gray-900"
            >
              Save
            </button> */}
          </form>
        </div>
      </div>
      <div className="flex-col justify-center flex-1 p-6 mx-auto my-12 border rounded-lg rightside bg-red-50 dark:bg-gray-800">
        <h2 className="mb-4 text-xl font-medium tracking-tight text-slate-900 sm:mb-6 leding-tight dark:text-white">
          Change Password
        </h2>
        <form onSubmit={handleChangePassword}>
          <div className="grid gap-5 my-6">
            <div className="relative">
              <label
                htmlFor="current-password"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Current Password
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="current-password"
                id="current-password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="••••••••"
                className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 top-7"
                onClick={togglePasswordVisibility}
              >
                {!showPassword ? (
                  <svg
                    className="w-4 h-4 text-gray-400 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-4 h-4 text-gray-400 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 14"
                  >
                    <g
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    >
                      <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                    </g>
                  </svg>
                )}
              </button>
            </div>

            <div className="relative">
              <label
                htmlFor="new-password"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                New Password
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="new-password"
                id="new-password"
                placeholder="••••••••"
                value={newPassword}
                onChange={handleNewPasswordChange}
                className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 top-0 right-0 flex items-center pr-3 text-sm leading-5"
                onClick={togglePasswordVisibility}
              >
                {!showPassword ? (
                  <svg
                    className="w-4 h-4 text-gray-400 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-4 h-4 text-gray-400 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 14"
                  >
                    <g
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    >
                      <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                    </g>
                  </svg>
                )}
              </button>
              <ul className="flex mt-2 text-xs">
                <li
                  className={
                    passwordMeetsLength
                      ? 'text-green-700 bg-green-100 dark:bg-green-300 dark:text-black mx-1 rounded p-1'
                      : newPassword
                        ? 'text-red-700 bg-red-100 dark:bg-red-300 dark:text-black mx-1 rounded p-1'
                        : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                  }
                >
                  Min. 8 Characters
                </li>
                <li
                  className={
                    passwordHasNumber
                      ? 'text-green-700 bg-green-100 mx-1 rounded p-1 dark:bg-green-300 dark:text-black'
                      : newPassword
                        ? 'text-red-700 bg-red-100 mx-1 rounded p-1 dark:bg-red-300 dark:text-black'
                        : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                  }
                >
                  1 Number
                </li>
                <li
                  className={
                    passwordHasUppercase
                      ? 'text-green-700 bg-green-100 mx-1 rounded p-1 dark:bg-green-300 dark:text-black'
                      : newPassword
                        ? 'text-red-700 bg-red-100 mx-1 rounded p-1 dark:bg-red-300 dark:text-black'
                        : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                  }
                >
                  1 Uppercase Letter
                </li>
              </ul>
            </div>

            <div className="relative">
              <label
                htmlFor="confirm-new-password"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Confirm New Password
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="confirm-new-password"
                id="confirm-new-password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                placeholder="••••••••"
                className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 top-7"
                onClick={togglePasswordVisibility}
              >
                {!showPassword ? (
                  <svg
                    className="w-4 h-4 text-gray-400 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-4 h-4 text-gray-400 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 14"
                  >
                    <g
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    >
                      <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                    </g>
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div className="flex space-x-3">
            <button
              type="submit"
              disabled={loadingChangePassword}
              className="flex items-center justify-center w-1/4 min-w-[200px] h-12 px-5 text-sm font-medium text-center text-white rounded-md bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-gray-900 dark:hover:bg-slate-700 dark:focus:ring-slate-800 whitespace-nowrap"
            >
              {loadingChangePassword ? <Spinner /> : <span>Save</span>}
            </button>
            {showPasswordMessage && (
              <span className="flex items-center px-2 text-gray-600 bg-white rounded-lg shadow dark:text-gray-200 dark:bg-gray-900 whitespace-nowrap">
                {passwordMessage}
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileContent;
