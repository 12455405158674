import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { useUser } from '../UserContext';
const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

const CheckoutSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const requestSentRef = useRef(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const session_id = searchParams.get('session_id');
  const { checkTokenExpiration, fetchAndUpdateUserDetails, customAxios } =
    useUser();

  const forwardToServer = async () => {
    if (session_id && !requestSentRef.current) {
      try {
        requestSentRef.current = true;
        const response = await axios.get(
          `${frontendServerUrl}/checkout-success`,
          {
            params: { session_id },
          },
        );
        if (response.status === 200) {
          if (!checkTokenExpiration()) {
            await fetchAndUpdateUserDetails();

            const params = JSON.parse(
              localStorage.getItem('autoTopUpEnabled') || '{}',
            );

            if (params?.threshold && params?.topup) {
              try {
                const data = await customAxios.put('/credits/preferences', {
                  top_up_threshold: params.threshold,
                  top_up_amount: params.topup,
                });
                if (data.status === 200) {
                  localStorage.removeItem('autoTopUpEnabled');
                  toast.success('Auto top-up set up successfully');
                }
              } catch (error) {
                if (import.meta.env.DEV) {
                  console.error('error:', error.message);
                }
                toast.error('Auto top-up failed to set up.');
              } finally {
                await fetchAndUpdateUserDetails();
              }
            }
          }
        } else {
          throw new Error('Failed to verify payment method');
        }

        const navigateTo = localStorage.getItem('afterStripeRedirect');
        localStorage.removeItem('afterStripeRedirect');
        toast.success('Payment method added successfully');
        navigateTo ? navigate(navigateTo) : navigate('/tailor');
      } catch (error) {
        setLoading(false);
        setError(true);
        if (import.meta.env.DEV) {
          console.error('error:', error);
        }

        if (error.code === 'ERR_NETWORK') {
          toast.error('Network error. Please try again later.', { id: 'network-error'});
        } else {
          toast.error('An error occurred. Please try again');
        }
      }
    } else {
      if (!checkTokenExpiration()) {
        await fetchAndUpdateUserDetails();
      }
    }
  };

  useEffect(() => {
    if (session_id === null) {
      const timeoutId = setTimeout(() => {
        navigate('/tailor');
      }, 2000);

      return () => clearTimeout(timeoutId);
    } else {
      forwardToServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_id]);

  const handleRetry = () => {
    setError(null);
    setLoading(true);
    requestSentRef.current = false;
    forwardToServer();
  };

  return (
    <div className="sm:min-h-[calc(100vh-88px-118px)] min-h-[calc(100vh-88px-130px)] lg:min-h-[calc(100vh-96px-118px)]">
      {session_id && (
        <div className="flex flex-col items-center w-full pt-24 justify-self-center ">
          <div className="z-0 flex flex-col my-12 mb-12 rounded-lg md:w-[600px] wavyslow shadow-3xinner justify-center w-4/5">
            <div className="absolute inset-0 z-0 hidden rounded-lg bg-gray-800/80 dark:block"></div>
            <div className="h-full m-0.5 bg-white/50 dark:bg-gray-800/70 rounded-md z-20 ">
              <div className="flex flex-row items-center justify-between">
                <h1 className="relative -top-5 left-7 text-2xl italic tracking-wide text-slate-800 font-extralight backdrop-blur-sm pl-0.5 pr-9 dark:text-gray-100 rounded-full">
                  {error && 'Error '}Processing Payment Verification
                </h1>
              </div>

              <div
                className={`z-20 flex items-center justify-center w-16 h-16 mx-auto my-10 bg-white rounded-lg shadow-md md:w-24 md:h-24 dark:bg-gray-800 dark:text-gray-100 ${error ? null : 'animate-bouncer'}`}
              >
                {loading ? (
                  <div
                    className="inline-block w-6 h-6 border-4 rounded-full spinner-border animate-spin"
                    style={{
                      borderColor: 'rgba(255, 255, 255, 0.3)',
                      borderLeftColor: 'rgb(104 117 245)',
                    }}
                  ></div>
                ) : (
                  <div className="opacity-0 animate-fadeIn">
                    <svg
                      className={`w-8 h-8 ${error ? 'text-yellow-300 dark:text-yellow-300' : 'text-green-500 dark:text-white'} md:w-10 md:h-10`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox={error ? '0 0 512 512 ' : '0 0 20 20'}
                    >
                      {error ? (
                        <path d="m504 256c0 136.997-111.043 248-248 248s-248-111.003-248-248c0-136.917 111.043-248 248-248s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346 7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
                      ) : (
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      )}
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
          {error && (
            <>
              <p className="px-10 mx-auto mb-10 text-sm font-semibold text-center text-red-700 dark:text-red-100">
                There was an error verifying your payment method.
                <p> Try again or contact support for assistance.</p>
              </p>
              <button
                onClick={handleRetry}
                className="px-8 py-2 text-white rounded-md w-fit bg-slate-700 hover:bg-slate-800 dark:bg-slate-600 dark:hover:bg-slate-700 animate-fadeIn"
              >
                Retry
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckoutSuccess;
