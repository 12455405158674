import { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import ErrorMessageInsideModal from '../Components/ErrorMessageInsideModal';
const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

const ChangePassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordMeetsLength, setPasswordMeetsLength] = useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordHasUppercase, setPasswordHasUppercase] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [showToast, setShowToast] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the token from the URL path
  const token = location.pathname.split('/').pop();

  const handlePasswordReset = async (event) => {
    setLoading(true);
    event.preventDefault();

    // password must meet criteria
    if (!passwordMeetsLength || !passwordHasNumber || !passwordHasUppercase) {
      setPasswordError(
        'Password must be at least 8 characters long and include one number and one uppercase letter.',
      );
      setLoading(false);
      return;
    }

    // passwords must match
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${frontendServerUrl}/reset-password/${token}`, {
        email,
        password: newPassword,
      });
      toast.success('Password Updated successfully');
      // setShowToast(true);
      // setTimeout(() => {
      //   navigate('/');
      // }, 3000);
      navigate('/');
      // console.log(response.data);
      // Handle success
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
      if (error.code === 'users.password_reset.expired_token') {
        setPasswordError(
          'This reset password request has expired. Please request a new link',
        );
      } else {
        setPasswordError(
          error.message || 'An error occurred, please try again',
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // if (name === 'password') {
  //   updatePasswordCriteria(value);
  //   setPasswordError('');
  // }

  const updatePasswordCriteria = (password) => {
    setPasswordMeetsLength(password.length >= 8);
    setPasswordHasNumber(/\d/.test(password));
    setPasswordHasUppercase(/[A-Z]/.test(password));
  };

  const handleChangePassword = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);
    updatePasswordCriteria(newPasswordValue);
    setPasswordError('');
  };

  // const handleInputChange = (name, value) => {
  //   if (name === 'password') {
  //     setNewPassword(value);
  //     // Update criteria here
  //   } else if (name === 'confirmPassword') {
  //     setConfirmPassword(value);
  //   } else if (name === 'email') {
  //     setEmail(value);
  //   }
  //   setPasswordError('');
  // };

  // const closeToast = () => {
  //   setShowToast(false);
  // };

  return (
    <>
      {/* {showToast && (
        <div
          id=""
          className="bg-white dark:bg-gray-900 fixed top-24 right-5 flex items-center w-full max-w-xs p-0.5 text-gray-900 font-semibold  rounded-lg dark:text-gray-100  justify-center z-50 h-16 shadow-md flex-col"
          role="alert"
        >
          <div className="flex items-center justify-center w-full h-full rounded-md bg-green-50 dark:bg-gray-900">
            <svg
              className="w-4 h-4 mr-2 text-green-800 dark:text-green-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            Password Updated
          </div>
        </div>
      )} */}
      <div className="font-spaceG ">
        <section className="min-h-screen bg-gray-50 dark:bg-gray-900">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-[calc(100vh-96px)] lg:py-0">
            <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
              <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Change your Password
              </h2>
              <form
                className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
                onSubmit={handlePasswordReset}
              >
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
                    placeholder="name@email.com"
                    required
                  />
                </div>
                <div className="relative">
                  <label
                    for="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    New Password
                  </label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    value={newPassword}
                    onChange={handleChangePassword}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
                    placeholder="••••••••"
                    required
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 bottom-1"
                    onClick={togglePasswordVisibility}
                  >
                    {!showPassword ? (
                      <svg
                        className="w-4 h-4 text-gray-400 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-4 h-4 text-gray-400 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 14"
                      >
                        <g
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        >
                          <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                          <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                        </g>
                      </svg>
                    )}
                  </button>
                  <ul className="flex mt-2 text-xs">
                    <li
                      className={
                        passwordMeetsLength
                          ? 'text-green-700 bg-green-100 dark:bg-green-300 dark:text-black mx-1 rounded p-1'
                          : newPassword
                            ? 'text-red-700 bg-red-100 dark:bg-red-300 dark:text-black mx-1 rounded p-1'
                            : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                      }
                    >
                      Min. 8 Characters
                    </li>
                    <li
                      className={
                        passwordHasNumber
                          ? 'text-green-700 bg-green-100 mx-1 rounded p-1 dark:bg-green-300 dark:text-black'
                          : newPassword
                            ? 'text-red-700 bg-red-100 mx-1 rounded p-1 dark:bg-red-300 dark:text-black'
                            : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                      }
                    >
                      1 Number
                    </li>
                    <li
                      className={
                        passwordHasUppercase
                          ? 'text-green-700 bg-green-100 mx-1 rounded p-1 dark:bg-green-300 dark:text-black'
                          : newPassword
                            ? 'text-red-700 bg-red-100 mx-1 rounded p-1 dark:bg-red-300 dark:text-black'
                            : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                      }
                    >
                      1 Uppercase Letter
                    </li>
                  </ul>
                </div>
                <div className="relative">
                  <label
                    for="confirm-password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Confirm password
                  </label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    id="confirm-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
                    required
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 top-6"
                    onClick={togglePasswordVisibility}
                  >
                    {!showPassword ? (
                      <svg
                        className="w-4 h-4 text-gray-400 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-4 h-4 text-gray-400 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 14"
                      >
                        <g
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        >
                          <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                          <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                        </g>
                      </svg>
                    )}
                  </button>
                </div>

                <ErrorMessageInsideModal errorMessage={passwordError} />

                <button
                  type="submit"
                  className={`flex w-full text-white bg-slate-600 hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm px-5 h-12  text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800`}
                >
                  {loading ? (
                    <div
                      className="flex items-center justify-center w-full mx-auto my-auto spinner"
                      style={{
                        border: '4px solid rgba(255, 255, 255, 0.3)',
                        borderTop: '4px solid white',
                        borderRadius: '50%',
                        width: '24px',
                        height: '24px',
                        animation: 'spin 1s linear infinite',
                      }}
                    ></div>
                  ) : (
                    <span className="flex items-center justify-center w-full my-auto">
                      Reset Password
                    </span>
                  )}
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChangePassword;
