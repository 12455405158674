import React, { useEffect, useState } from 'react';
import { Label, Modal, Checkbox } from 'flowbite-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

import { useUser } from '../../UserContext';
// import { jwtDecode } from 'jwt-decode';
import ErrorMessageInsideModal from '../ErrorMessageInsideModal';

// import { applyDarkModePreference } from '../../utils/generalUtils';
import Spinner from '../Spinner';
import { sanitizeEmail } from '../../utils/userUtils';
import googleIcon from '../../assets/google-icon.svg';
import { set, get } from 'idb-keyval';

// import githubIcon from '../../assets/github-icon.svg';

const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;
const githubClientId = import.meta.env.VITE_GITHUB_CLIENT_ID;
const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const baseUrl = import.meta.env.VITE_BASE_URL;

export default function SingInModal() {
  const {
    showTokenExpiredModal,
    setShowTokenExpiredModal,
    showSignInModal,
    setShowSignInModal,
    updateUserDetails,
  } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const postLoginRedirect = localStorage.getItem('postLoginRedirect');
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginError = (error) => {
    if (error.code === 'ERR_NETWORK') {
      if (import.meta.env.DEV) {
        console.error('Error Logging in:', error);
      }
      toast.error('Network error. Please try again later.', { id: 'network-error'});
    } else if (
      error?.response?.data?.code === 'missing_parameter.email' ||
      error?.response?.data?.code === 'missing_parameter.password' ||
      error?.response?.data?.code === 'users.login.invalid'
    ) {
      if (import.meta.env.DEV) {
        console.error('Error Logging in: invalid credentials', error);
      }
      setErrorMessage('Invalid credentials');
    } else {
      if (import.meta.env.DEV) {
        console.error('Error Logging in:', error);
      }
      setErrorMessage('Your login attempt was unsuccessful. Please try again.');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    const loginDetails = {
      email: await sanitizeEmail(email),
      // email,
      password,
    };
    let isProvider = false;

    try {
      const response = await axios.post(
        `${frontendServerUrl}/login`,
        loginDetails,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const authHeader =
        response.headers['authorization'] || response.headers['Authorization'];

      let token;
      if (
        authHeader &&
        authHeader.startsWith('Bearer ') &&
        authHeader.length > 7 &&
        typeof authHeader === 'string' &&
        authHeader.trim() !== ''
      ) {
        token = authHeader.substring(7);
      } else {
        console.error('Problems with the Token from server');
        throw new Error('There was an error logging in. Please try again.');
      }

      localStorage.setItem('token', token);
      const userDetails = await updateUserDetails(response.data.user);

      if (postLoginRedirect) {
        navigate(postLoginRedirect);
        localStorage.removeItem('postLoginRedirect');
      }
      // if (userDetails.is_provider) {
      //   const currentTheme = localStorage.getItem('darkMode');
      //   if (!currentTheme) {
      //     localStorage.setItem('darkMode', 'true');
      //     document.documentElement.classList.add('dark');
      //   } else {
      //     applyDarkModePreference();
      //   }
      //   isProvider = true;
      // } else {
      //   applyDarkModePreference();
      // }
      setEmail('');
      setPassword('');
      setShowSignInModal(false);
      setShowTokenExpiredModal(false);
      redirectToPath(isProvider);
    } catch (error) {
      handleLoginError(error);
    } finally {
      setLoading(false);
    }
  };

  const redirectToPath = (isProvider) => {
    setShowTokenExpiredModal(false);
    const shouldRedirect = localStorage.getItem('shouldRedirect');
    localStorage.removeItem('shouldRedirect');
    if (shouldRedirect === 'no') {
      return;
    } else {
      const redirectPath = '/tailor';
      navigate(redirectPath);
    }
  };

  // function applyDarkModePreference() {
  //   const currentTheme = localStorage.getItem('darkMode');
  //   if (currentTheme === 'true') {
  //     document.documentElement.classList.add('dark');
  //   } else {
  //     document.documentElement.classList.remove('dark');
  //   }
  // }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage('');
  };

  const getModalHeaderText = () => {
    return showTokenExpiredModal
      ? 'Session expired, please log in again.'
      : 'Sign in to our platform';
  };

  const handleCloseModal = () => {
    if (showTokenExpiredModal) {
      setShowTokenExpiredModal(false);
    } else {
      setShowSignInModal(false);
    }
    setEmail('');
    setPassword('');
    setErrorMessage('');
  };

  const handleSignInWithGoogle = async () => {
    const state = `google-${uuidv4()}`;
    localStorage.setItem('state', state);
    setLoading(true);
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
    const params = url.searchParams;
    if (!googleClientId) {
      toast.error(
        'There was an error logging in with Google. Try another method.',
      );
      setLoading(false);
      return;
    }
    params.append('client_id', googleClientId);
    params.append('response_type', 'code');
    params.append('scope', 'email profile openid');
    params.append('state', state);
    params.append('redirect_uri', `${baseUrl}/login/google/callback`);

    try {
      window.location.assign(url.toString());
    } catch (error) {
      console.error('Error Logging in with Google:', error);
      toast.error(
        'There was an error logging in with Google. Please try again!',
      );
    } finally {
      setShowSignInModal(false);
      setShowTokenExpiredModal(false);
      setLoading(false);
    }
  };

  const handleSignInWithGithub = async () => {
    const state = `github-${uuidv4()}`;
    localStorage.setItem('state', state);
    setLoading(true);
    const url = new URL('https://github.com/login/oauth/authorize');
    const params = url.searchParams;
    if (!githubClientId) {
      toast.error(
        'There was an error logging in with GitHub. Try another method.',
      );
      setLoading(false);
      return;
    }
    params.append('client_id', githubClientId);
    params.append('redirect_uri', `${baseUrl}/login/github/callback`);
    params.append('state', state);

    try {
      window.location.assign(url.toString());
    } catch (error) {
      console.error('Error Logging in with GitHub:', error);
      toast.error(
        'There was an error logging in with GitHub. Please try again!',
      );
    } finally {
      setShowSignInModal(false);
      setShowTokenExpiredModal(false);
      setLoading(false);
    }
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
    // set rememberMe in indexedDB
    set('rememberMe', !rememberMe);
  };

  useEffect(() => {
    // retrieve rememberMe from indexedDB
    get('rememberMe').then((val) => {
      if (val) {
        setRememberMe(val);
      }
    });
  }, []);

  return (
    <Modal
      show={showSignInModal || showTokenExpiredModal}
      size="lg"
      popup
      onClose={handleCloseModal}
      dismissible
    >
      <div className="font-dmSans">
        <div className="rounded-md bg-zinc-50 dark:bg-gray-800">
          <Modal.Header>
            <img
              src="/rings-logo-white.png"
              className="h-10 mb-6 sm:h-16"
              alt="Logo"
            />
          </Modal.Header>

          <Modal.Body>
            <form onSubmit={handleLogin}>
              <div className="space-y-5">
                <h3 className="text-xl font-medium text-zinc-900 dark:text-white">
                  {getModalHeaderText()}
                </h3>
                <div>
                  <div className="block mb-1">
                    <Label htmlFor="email" value="Your email" />
                  </div>
                  <input
                    id="email"
                    type="email"
                    placeholder="name@company.com"
                    required
                    autoComplete="username"
                    value={email}
                    onChange={handleEmailChange}
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 text-zinc-900"
                  />
                </div>
                <div className="relative">
                  <div className="block mb-1">
                    <Label htmlFor="password" value="Your password" />
                  </div>
                  <input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    autoComplete="current-password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 text-zinc-900"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 top-8"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <svg
                        className="w-4 h-4 text-gray-400 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 14"
                      >
                        <g
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        >
                          <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                          <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        className="w-4 h-4 text-gray-400 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </button>
                </div>

                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    {/* <Checkbox
                      className="checked:bg-gray-800 dark:checked:bg-gray-200"
                      id="remember"
                      checked={rememberMe}
                      onChange={handleRememberMe}
                    />
                    <Label htmlFor="remember">Remember me</Label> */}
                  </div>
                  <Link
                    to="/forgot-password"
                    onClick={() => setShowSignInModal(false)}
                    className="text-sm text-zinc-700 hover:underline dark:text-slate-500"
                  >
                    Forgot Password?
                  </Link>
                </div>

                <div className="w-full">
                  <ErrorMessageInsideModal errorMessage={errorMessage} />
                  {/* <span className="text-sm text-red-500 ">
                      {errorMessage || (
                        <span className="opacity-0">No Error</span>
                      )}
                    </span> */}
                  <button
                    type="submit"
                    disabled={loading || !!errorMessage}
                    className={`${'text-white bg-zinc-900 hover:bg-zinc-800'} focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-md text-sm px-4 h-10 text-center inline-flex items-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800 w-full justify-center mt-2`}
                  >
                    {loading ? <Spinner /> : 'Log in to your account'}
                  </button>
                </div>

                <div className="flex text-sm font-medium text-zinc-900 dark:text-slate-300">
                  Not registered?&nbsp;
                  <Link
                    to="/signup"
                    onClick={() => {
                      setShowSignInModal(false);
                      setEmail('');
                      setPassword('');
                    }}
                    className="ml-2 text-zinc-600 hover:underline dark:text-indigo-400"
                  >
                    Request access
                  </Link>
                </div>
              </div>
            </form>

            <div className="flex items-center gap-4 px-0 mt-10 mb-6 md:px-4">
              <div className="flex-grow h-px mt-1 bg-indigo-600/40"></div>
              <div className="text-sm text-gray-700"> Or continue with</div>
              <div className="flex-grow h-px mt-1 bg-indigo-600/40"></div>
            </div>
            <div className="flex gap-4 pb-6">
              <div className="flex-grow">
                <button
                  className="flex items-center justify-around w-full px-6 py-2 text-sm font-medium text-gray-800 bg-white border border-gray-300 rounded-lg shadow-sm dark:bg-gray-900 dark:text-white hover:bg-gray-200 focus:outline-none"
                  onClick={handleSignInWithGoogle}
                  type="button"
                  disabled={loading}
                >
                  <div className="flex items-center gap-2">
                    <img src={googleIcon} alt="Google" className="w-5 h-5" />
                    <span className="text-sm font-semibold leading-6">
                      Google
                    </span>
                  </div>
                </button>
              </div>
              {/* <div className="flex-grow gap-4">
                <button
                  className="flex items-center justify-around w-full px-6 py-2 text-sm font-medium text-gray-800 bg-white border border-gray-300 rounded-lg shadow-sm dark:bg-gray-900 dark:text-white hover:bg-gray-200 focus:outline-none"
                  onClick={handleSignInWithGithub}
                  type="button"
                  disabled={loading}
                >
                  <div className="flex items-center gap-2">
                    <img src={githubIcon} alt="Google" className="w-6 h-6" />
                    <span className="text-sm font-semibold leading-6">
                      GitHub
                    </span>
                  </div>
                </button>
              </div> */}
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
}
