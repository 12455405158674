import { Label, Tooltip } from 'flowbite-react';
import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useUser } from '../../UserContext';
import Spinner from '../Spinner';
import LogsUpload from './FineTunning/LogsUpload';
import FineTuneCreateErrorModal from './FineTunning/FineTuneCreateErrorModal';
import { requiredSecondsToTrain } from '../../utils/TailorUtils';
import { amountToFrontend } from '../../utils/CurrencyUtils';
import InsufficientCreditsModal from '../Modals/InsufficientCreditsModal';
import EstimateTimeAndCost from './FineTunning/EstimateTimeAndCost';

const advanced = {
  epochs: {
    description:
      'The number of epochs to train the model for. An epoch refers to one full cycle through the training dataset. Defaults to auto.',
    display_name: 'Epochs',
  },
  batchSize: {
    description:
      'Number of examples in each batch. A larger batch size means that model parameters are updated less frequently, but with lower variance. Defaults to auto.',
    display_name: 'Batch Size',
  },
  learningRate: {
    description:
      'Scaling factor for the learning rate. A smaller learning rate may be useful to avoid overfitting. Defaults to auto.',
    display_name: 'Learning Rate',
  },
};

const TailorFinetune = () => {
  const [loading, setLoading] = useState(false);
  const [dataInput, setDataInput] = useState('all');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { customAxios, user } = useUser();
  const navigate = useNavigate();
  const [userHasLogs, setUserHasLogs] = useState(true);
  const [advancedSettings, setAdvancedSettings] = useState({
    epochs: 'auto',
    batchSize: 'auto',
    learningRate: 'auto',
  });
  const [validationMessages, setValidationMessages] = useState({
    name: '',
    epochs: '',
    batchSize: '',
    learningRate: '',
    tags: '',
    file: '',
    datasets: '',
  });
  const [modelName, setModelName] = useState('');
  const [selectedBaseModel, setSelectedBaseModel] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fileContent, setFileContent] = useState({
    name: '',
    content: null,
    numberOfLogs: 0,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [saveLogsTagList, setSaveLogsTagList] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [skipLogsWithError, setSkipLogsWithError] = useState(true);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] =
    useState(false);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [logsUsed, setLogsUsed] = useState(0);
  const [baseModels, setBaseModels] = useState([]);

  const toggleTags = (tag) => {
    setSelectedTags((prevSelected) =>
      prevSelected.includes(tag)
        ? prevSelected.filter((t) => t !== tag)
        : [...prevSelected, tag],
    );
  };

  const fetchBaseModels = async () => {
    try {
      const response = await customAxios.get(
        'tailor/v1/base_models?finetuning=true',
      );
      const models = response?.data?.models;
      models.forEach((model) => {
        if (model.model_name.includes('mistral')) {
          model.min_logs_required = 1_000;
          model.good_number_of_logs_required = 2_000;
          model.excellent_number_of_logs_required = 5_000;
        } else if (model.model_name.includes('mixtral')) {
          model.min_logs_required = 2_000;
          model.good_number_of_logs_required = 4_000;
          model.excellent_number_of_logs_required = 8_000;
        } else if (
          model.model_name.includes('llama') ||
          model.model_name.includes('gemma') ||
          model.model_name.includes('dbrx') ||
          model.model_name.includes('phi')
        ) {
          model.min_logs_required = 8_000;
          model.good_number_of_logs_required = 12_000;
          model.excellent_number_of_logs_required = 18_000;
        }
      });
      setBaseModels(models);
      setSelectedBaseModel((prevSelected) => prevSelected || models[0] || null);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('An error occurred fetching base models. Please try again.');
    }
  };

  useEffect(() => {
    fetchBaseModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupedModels = (models) => {
    const families = {
      Mistral: [],
      Mixtral: [],
      LLaMA: [],
      Gemma: [],
      Phi: [],
      Databricks: [],
      Others: [],
    };

    models.forEach((model) => {
      if (model.model_name.includes('mistral')) {
        families.Mistral.push(model);
      } else if (model.model_name.includes('mixtral')) {
        families.Mixtral.push(model);
      } else if (model.model_name.includes('llama')) {
        families.LLaMA.push(model);
      } else if (model.model_name.includes('gemma')) {
        families.Gemma.push(model);
      } else if (model.model_name.includes('phi')) {
        families.Phi.push(model);
      } else if (model.model_name.includes('dbrx')) {
        families.Databricks.push(model);
      } else {
        families.Others.push(model);
      }
    });

    return Object.keys(families).map((family) => ({
      family,
      models: families[family],
    }));
  };

  const getImageUrlForFamily = (family) => {
    switch (family) {
      case 'Mistral':
      case 'Mixtral':
        return '/mistral.png';
      case 'LLaMA':
        return '/meta.png';
      case 'Gemma':
        return '/googleicon.png';
      case 'Phi':
        return '/microsoft.svg';
      case 'Databricks':
        return '/databricks.png';
      default:
        return '';
    }
  };

  const hasValidationMessages = () => {
    const relevantFields = ['epochs', 'batchSize', 'learningRate'];
    return relevantFields.some((field) => validationMessages[field].length > 0);
  };

  const hasEnoughCredits = useCallback(
    async (estimatedCost) => {
      const currentCreditsInDollars = amountToFrontend(
        user?.new_credits_available,
      );
      // console.log('currentCreditsInDollars', currentCreditsInDollars);
      if (currentCreditsInDollars < estimatedCost) {
        setShowInsufficientCreditsModal(true);
        return false;
      } else {
        return true;
      }
    },
    [user?.new_credits_available],
  );

  const resetAdvancedSettings = () => {
    setAdvancedSettings({
      epochs: 'auto',
      batchSize: 'auto',
      learningRate: 'auto',
    });
  };

  const handleShowAdvanced = () => {
    setShowAdvanced((prev) => {
      if (prev) {
        resetAdvancedSettings();
      }
      return !prev;
    });
  };

  const errorShownRef = useRef(false);

  const fetchTags = useCallback(async () => {
    if (!user?.confirmed) {
      toast.error('Please confirm your email address to access this feature.');
      return;
    }
    try {
      const response = await customAxios.get('tailor/v1/tags');
      const tags =
        response?.data?.message
          ?.map((tag) => (tag === '[]' ? null : tag.replace(/[[\]{}"]+/g, '')))
          .filter(Boolean) || [];
      setTagList([...new Set(tags)]);

      const userHasLogs = !!response?.data?.total_logs;
      setUserHasLogs(userHasLogs);

      setDatasets(response?.data?.datasets || []);
    } catch (error) {
      if (!errorShownRef.current) {
        errorShownRef.current = true;
        if (import.meta.env.DEV) {
          console.error(error);
        }
        toast.error('An error occurred fetching tags. Please try again later.');
        setTimeout(() => {
          errorShownRef.current = false;
        }, 3000);
      }
    }
  }, [customAxios, user.confirmed]);

  useEffect(() => {
    if (userHasLogs) {
      setDataInput('all');
    } else {
      setDataInput('upload');
    }
  }, [userHasLogs]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  useEffect(() => {
    if (selectedBaseModel) {
      advanced.batchSize.description = `Number of examples in each batch. A larger batch size means that model parameters are updated less frequently, but with lower variance. Defaults to auto. (Auto: ${selectedBaseModel.default_batch_size})`;

      advanced.learningRate.description = `Scaling factor for the learning rate. A smaller learning rate may be useful to avoid overfitting. Defaults to auto. (Auto: ${selectedBaseModel.default_lr})`;

      advanced.epochs.description = `The number of epochs to train the model for. An epoch refers to one full cycle through the training dataset. Defaults to auto. (Auto: 1)`;
    }
  }, [selectedBaseModel]);

  const getTrainingCostEstimate = useCallback(
    async (type, name = null) => {
      let logs;

      if (type !== 'upload') {
        const payload = {
          type,
        };
        if (type !== 'all' && name) {
          payload.names = Array.isArray(name) ? name : [name];
        }
        try {
          const response = await customAxios.post(
            'tailor/v1/training_logs_count',
            payload,
          );
          logs = response?.data?.total_logs;
        } catch (error) {
          if (import.meta.env.DEV) {
            console.error(error);
          }
          toast.error(
            'An error occurred fetching the cost estimate. Please try again later.',
            {
              id: 'network-error-cost-estimate',
            },
          );
        }
      } else if (type === 'upload') {
        logs = fileContent?.numberOfLogs;
      }

      const time =
        logs *
          selectedBaseModel.training_time_per_log *
          (advancedSettings.epochs !== 'auto' ? advancedSettings.epochs : 1) +
        selectedBaseModel.training_time_y_intercept;

      // const time2 = requiredSecondsToTrain(
      //   selectedBaseModel,
      //   logs,
      //   advancedSettings.epochs !== 'auto' ? advancedSettings.epochs : 1,
      // );
      setEstimatedTime(time);
      const estimatedCost = (time * 10.88) / 3600;
      setEstimatedCost(estimatedCost);
      setLogsUsed(logs);
    },
    [selectedBaseModel, advancedSettings, customAxios, fileContent],
  );

  useEffect(() => {
    if (dataInput === 'all') {
      getTrainingCostEstimate('all');
    } else if (dataInput === 'tags' && selectedTags.length > 0) {
      getTrainingCostEstimate('tags', selectedTags);
    } else if (dataInput === 'datasets' && selectedDataset) {
      getTrainingCostEstimate('dataset', selectedDataset.name);
    } else if (dataInput === 'upload' && fileContent.numberOfLogs > 0) {
      getTrainingCostEstimate('upload');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataInput,
    selectedDataset,
    selectedTags,
    fileContent.numberOfLogs,
    selectedBaseModel,
    advancedSettings,
    showAdvanced,
  ]);

  const handleCreate = async (e) => {
    e.preventDefault();
    clearError();
    if (!validateForm()) {
      return;
    }
    if (!(await hasEnoughCredits(estimatedCost))) {
      return;
    }
    setLoading(true);

    let filename = '';
    const payload = {
      model_name: modelName,
      base_model: selectedBaseModel.model_name,
      epoch: advancedSettings.epochs === 'auto' ? '' : advancedSettings.epochs,
      batch_size:
        advancedSettings.batchSize === 'auto' ? '' : advancedSettings.batchSize,
      learning_rate:
        advancedSettings.learningRate === 'auto'
          ? ''
          : advancedSettings.learningRate,
      skip_logs_with_errors: skipLogsWithError,
    };

    if (dataInput === 'upload' && fileContent) {
      try {
        const response = await customAxios.get('tailor/v1/generate_signed_url');
        const url = response?.data?.signedUrl;
        filename = response?.data?.filename;

        const formData = new FormData();
        formData.append('file', selectedFile);
        const res = await fetch(url, {
          method: 'PUT',
          body: formData,
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        });

        if (!res.ok) {
          throw new Error('Error uploading file');
        } else {
          payload.custom_logs_filename = filename;
          payload.save_logs_with_tags = saveLogsTagList;
        }
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        toast.error('An error occurred uploading the file. Please try again.');
        setLoading(false);
        return;
      }
    }

    if (dataInput === 'tags' && selectedTags.length > 0) {
      payload.tags = selectedTags;
    }

    if (dataInput === 'datasets') {
      if (!selectedDataset) {
        setValidationMessages({
          ...validationMessages,
          datasets: 'Please select a dataset.',
        });
        setLoading(false);
        return;
      }
      payload.custom_dataset = selectedDataset.name;
    }

    try {
      const response = await customAxios.post(
        'tailor/v1/training-pod',
        payload,
      );
      if (response.status === 201) {
        toast.success('Fine-tuning job created successfully');
        navigate('/tailor/training-metrics');
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      handleCreateJobError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateJobError = (error) => {
    const validationMessages = {
      name: '',
      epochs: '',
      batchSize: '',
      learningRate: '',
      datasets: '',
      tags: '',
      file: '',
    };
    if (error.code === 'ERR_NETWORK') {
      toast.error('Network error. Please try again later.', {
        id: 'network-error',
      });
      return;
    }
    switch (error.code) {
      case 'missing_parameter.model_name':
      case 'model.duplicate_name':
        setValidationMessages({
          ...validationMessages,
          name: error.message,
        });
        break;
      case 'delinquent_user':
      case 'node.missing':
      case 'pods.create.no_payment_method':
      case 'insufficient_credits':
        setErrorCode(error.code);
        setShowErrorModal(true);
        break;
      default:
        setErrorCode('unknown');
        setShowErrorModal(true);
    }
  };

  const clearError = () => {
    setErrorCode('');
    setShowErrorModal(false);
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {
      name: '',
      epochs: '',
      batchSize: '',
      learningRate: '',
      tags: '',
      file: '',
    };

    if (!modelName) {
      errors.name = 'Name is required.';
      isValid = false;
    }
    if (dataInput === 'tags' && selectedTags.length === 0) {
      errors.tags = 'At least one tag must be selected.';
      isValid = false;
    }
    if (
      advancedSettings.epochs !== 'auto' &&
      (Number.isNaN(advancedSettings.epochs) ||
        parseInt(advancedSettings.epochs) < 1)
    ) {
      errors.epochs = 'Epochs must be a positive number or "auto".';
      isValid = false;
    }
    if (
      advancedSettings.batchSize !== 'auto' &&
      (Number.isNaN(advancedSettings.batchSize) ||
        parseInt(advancedSettings.batchSize) < 1)
    ) {
      errors.batchSize = 'Batch size must be a positive number or "auto".';
      isValid = false;
    }
    if (
      advancedSettings.learningRate !== 'auto' &&
      (Number.isNaN(advancedSettings.learningRate) ||
        parseFloat(advancedSettings.learningRate) <= 0)
    ) {
      errors.learningRate =
        'Learning rate must be a positive number or "auto".';
      isValid = false;
    }
    if (dataInput === 'upload' && !fileContent.name) {
      errors.file = 'You must select a valid file to upload.';
      isValid = false;
    }

    if (dataInput === 'datasets' && !selectedDataset) {
      errors.datasets = 'Please select a dataset.';
      isValid = false;
    }

    setValidationMessages(errors);
    return isValid;
  };

  const isTagSelected = (tag) => selectedTags?.includes(tag);

  return (
    <>
      <div className="flex flex-col min-h-screen bg-zinc-50 font-dmSans">
        <header className="pt-10 lg:pt-0">
          <div
            className="flex justify-between h-16 p-4 text-xl font-medium text-zinc-800"
            role="banner"
          >
            Fine-tune
            <div></div>
          </div>
          <hr className="border-t border-zinc-300" />
        </header>
        <div className="p-4 text-lg font-medium text-zinc-800">
          Create a new fine-tuning job
        </div>
        <div>
          <form onSubmit={handleCreate} className="px-4 sm:max-w-[600px] ">
            <div className="relative w-full">
              <label
                htmlFor="finetuneName"
                className="block mt-4 mb-1 text-sm font-medium text-zinc-800"
              >
                Model Name
              </label>
              <input
                type="text"
                id="finetuneName"
                name="finetuneName"
                aria-describedby="name-error"
                className={`w-full h-10 px-3 border rounded shadow border-zinc-300 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200 placeholder:text-zinc-400 ${validationMessages.name ? '!border-red-500' : null}`}
                placeholder="Enter a name for the fine-tuning job"
                value={modelName}
                onChange={(e) => setModelName(e.target.value.trim())}
                onFocus={() =>
                  setValidationMessages({ ...validationMessages, name: '' })
                }
              />
              <p
                id="name-error"
                className={` absolute right-0 h-8 text-xs italic text-red-500 ${validationMessages.name ? null : 'invisible'}`}
              >
                {validationMessages.name || ' '}
              </p>
              <Listbox
                value={selectedBaseModel}
                onChange={setSelectedBaseModel}
                name="baseModel"
              >
                <Listbox.Label className="block mt-4 mb-1 text-sm font-medium text-zinc-800">
                  Base Model
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full h-10 pl-3 pr-10 text-left bg-white border rounded shadow cursor-default border-zinc-300 sm:text-sm focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200">
                    <span className="block truncate">
                      {selectedBaseModel?.display_name}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronUpDownIcon
                        className="w-5 h-5 text-zinc-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded shadow max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {/* Grouped Models */}
                      {groupedModels(baseModels).map((group) => (
                        <Fragment key={group.family}>
                          <div className="px-4 py-2 font-semibold text-gray-900 bg-gray-100">
                            <div className="flex flex-row items-center justify-start">
                              <img
                                src={getImageUrlForFamily(group.family)}
                                alt=""
                                className="w-auto h-5 mr-2"
                              />
                              {group.family}
                            </div>
                          </div>
                          {group.models.map((model) => (
                            <Listbox.Option
                              key={model.model_id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'}`
                              }
                              value={model}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                  >
                                    {model.display_name}
                                  </span>
                                  {selected && (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Fragment>
                      ))}
                      <Listbox.Option
                        className="relative py-2 pl-10 pr-4 text-gray-300 select-none"
                        disabled
                      >
                        <span className="block font-normal truncate">
                          More base models coming soon
                        </span>
                      </Listbox.Option>
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>

              {/* <div className="h-8 mt-px ml-1 text-xs text-zinc-600">
                <div>
                  We recommend having around 10,000 logs to fine-tune a model
                  effectively.
                </div>
              </div> */}
            </div>

            <div className="block mt-8 mb-1 text-sm font-medium text-zinc-800">
              Select your Training Data
            </div>
            <div className="items-center gap-6 mx-1 mt-1 mb-3 md:flex">
              <div className="flex items-center mt-2 min-w-fit w-fit shrink-0 md:mt-0">
                <input
                  type="radio"
                  id="all"
                  name="dataInput"
                  value="all"
                  disabled={!userHasLogs}
                  className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
                  checked={dataInput === 'all'}
                  onChange={() => {
                    setFileContent({
                      name: '',
                      content: null,
                      numberOfLogs: 0,
                    });
                    setSelectedDataset(null);
                    setSelectedTags([]);
                    setDataInput('all');
                    setValidationMessages({
                      ...validationMessages,
                      tags: '',
                      file: '',
                    });
                  }}
                />
                <label
                  htmlFor="all"
                  className="ml-2 text-sm font-normal text-zinc-800 peer-disabled:opacity-50"
                >
                  All logs
                </label>
              </div>
              <div className="flex items-center mt-3 md:mt-0">
                <input
                  type="radio"
                  id="tags"
                  name="dataInput"
                  value="tags"
                  className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
                  checked={dataInput === 'tags'}
                  onChange={() => setDataInput('tags')}
                  disabled={tagList?.length === 0}
                  onFocus={() => {
                    setFileContent({
                      name: '',
                      content: null,
                      numberOfLogs: 0,
                    });
                    setSelectedDataset(null);
                    setValidationMessages({
                      ...validationMessages,
                      tags: '',
                      file: '',
                      selectedDataset: '',
                    });
                  }}
                />
                <label
                  htmlFor="tags"
                  className="ml-2 text-sm font-normal text-zinc-800 peer-disabled:opacity-50"
                >
                  By tags
                  <span className="ml-2 text-xs text-zinc-500">
                    (select multiple)
                  </span>
                </label>
              </div>
              <div className="flex items-center mt-3 md:mt-0">
                <input
                  type="radio"
                  id="datasets"
                  name="dataInput"
                  value="datasets"
                  className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
                  checked={dataInput === 'datasets'}
                  onChange={() => setDataInput('datasets')}
                  disabled={datasets.length === 0}
                  onFocus={() => {
                    setFileContent({
                      name: '',
                      content: null,
                      numberOfLogs: 0,
                    });
                    setSelectedTags([]);
                    setValidationMessages({
                      ...validationMessages,
                      tags: '',
                      file: '',
                    });
                  }}
                />
                <label
                  htmlFor="datasets"
                  className="ml-2 text-sm font-normal text-zinc-800 peer-disabled:opacity-50"
                >
                  By dataset
                </label>
              </div>

              <div className="flex items-center mt-3 md:mt-0">
                <input
                  type="radio"
                  id="upload"
                  name="dataInput"
                  value="upload"
                  className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
                  checked={dataInput === 'upload'}
                  onChange={() => setDataInput('upload')}
                  onFocus={() => {
                    setSelectedTags([]);
                    setSelectedDataset(null);
                    setValidationMessages({
                      ...validationMessages,
                      tags: '',
                      file: '',
                      selectedDataset: '',
                    });
                  }}
                />
                <label
                  htmlFor="upload"
                  className="ml-2 text-sm font-normal text-zinc-800 peer-disabled:opacity-50"
                >
                  Upload logs
                </label>
              </div>
            </div>

            {dataInput === 'tags' && (
              <>
                <div
                  className={clsx(
                    'flex flex-wrap content-start gap-x-2 gap-y-3 p-2 overflow-y-scroll border rounded max-h-40 h-40 shadow',
                    tagList.length === 0 ? 'hidden' : 'visible',
                    validationMessages.tags
                      ? 'border-red-500'
                      : 'border-gray-200',
                  )}
                >
                  {tagList.map((tag, index) => (
                    <div
                      key={index + tag}
                      className={clsx(
                        'flex flex-col items-center justify-start p-1 rounded-full shadow cursor-pointer border text-sm font-normal text-zinc-800 h-fit w-fit px-4',
                        isTagSelected(tag)
                          ? 'bg-indigo-100 shadow-indigo-200'
                          : 'bg-white border-transparent ',
                      )}
                      onClick={() => toggleTags(tag)}
                      role="button"
                      aria-pressed={isTagSelected(tag)}
                    >
                      {tag}
                    </div>
                  ))}
                </div>
                {validationMessages.tags && (
                  <p className="text-xs italic text-red-500">
                    {validationMessages.tags}
                  </p>
                )}
              </>
            )}
            {dataInput === 'upload' && (
              <LogsUpload
                validationInProgress={validationInProgress}
                setValidationInProgress={setValidationInProgress}
                fileContent={fileContent}
                setFileContent={setFileContent}
                validationError={validationMessages.file}
                setValidationError={(message) =>
                  setValidationMessages({
                    ...validationMessages,
                    file: message,
                  })
                }
                setSelectedFile={setSelectedFile}
                saveLogsTagList={saveLogsTagList}
                setSaveLogsTagList={setSaveLogsTagList}
              />
            )}

            {dataInput === 'datasets' && (
              <div className="mt-4">
                <Listbox value={selectedDataset} onChange={setSelectedDataset}>
                  <div className="relative mt-1">
                    <Listbox.Button
                      onFocus={() =>
                        setValidationMessages({
                          ...validationMessages,
                          datasets: '',
                        })
                      }
                      className={clsx(
                        'relative w-full h-10 pl-3 pr-10 text-left bg-white border rounded shadow cursor-default border-gray-300 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200 text-gray-900',
                        validationMessages.datasets ? '!border-red-500' : '',
                      )}
                    >
                      <span className="block truncate">
                        {selectedDataset?.name || 'Select a dataset'}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronUpDownIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    {validationMessages.datasets && (
                      <p className="text-xs italic text-right text-red-500">
                        {validationMessages.datasets}
                      </p>
                    )}
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded shadow max-h-48 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {datasets.map((dataset) => (
                          <Listbox.Option
                            key={dataset.id}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'}`
                            }
                            value={dataset}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? 'font-medium' : 'font-normal'
                                  }`}
                                >
                                  {dataset.name}
                                </span>
                                {selected && (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            )}
            <div
              type="button"
              className="mt-8 ml-auto text-sm underline cursor-pointer underline-offset-4 text-zinc-500 w-fit"
              onClick={handleShowAdvanced}
            >
              Advanced settings{' '}
              {showAdvanced ? (
                <ChevronUpIcon className="inline w-4 h-4" />
              ) : (
                <ChevronDownIcon className="inline w-4 h-4" />
              )}
            </div>
            {showAdvanced && (
              <>
                <div className="mt-2 text-sm text-zinc-500">
                  These settings can have a significant impact on the outcome of
                  your fine-tuning job. If you are unsure, we recommend leaving
                  them as auto.
                </div>
                <div className="grid w-full grid-cols-3 gap-x-4">
                  {Object.keys(advanced).map((setting) => (
                    <div
                      key={setting}
                      className="flex flex-col justify-between"
                    >
                      <Label
                        htmlFor={setting}
                        className="flex items-center mt-4 mb-1 text-sm font-medium grow shrink-0 text-zinc-800 text-wrap"
                      >
                        <span className="min-w-fit">
                          {advanced[setting].display_name}
                        </span>
                        <Tooltip
                          className="z-50 shadow-lg max-w-48"
                          content={advanced[setting].description}
                          style="light"
                        >
                          <InformationCircleIcon className="w-4 h-4 ml-1 min-w-4 min-h-4 text-zinc-500" />
                        </Tooltip>
                      </Label>
                      <input
                        type="text"
                        id={setting}
                        name={setting}
                        aria-describedby={`${setting}-error`}
                        className={`w-full h-10 px-3 border rounded shadow border-zinc-300 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200 placeholder:text-zinc-400 ${validationMessages[setting] ? '!border-red-500' : ''}`}
                        placeholder="auto"
                        value={
                          advancedSettings[setting] === 'auto'
                            ? ''
                            : advancedSettings[setting]
                        }
                        onChange={(e) => {
                          setAdvancedSettings({
                            ...advancedSettings,
                            [setting]:
                              e.target.value === '' ? 'auto' : e.target.value,
                          });
                          setValidationMessages({
                            ...validationMessages,
                            [setting]: '',
                          });
                        }}
                      />
                      <div
                        className={hasValidationMessages() ? 'min-h-12' : ''}
                      >
                        {validationMessages[setting] && (
                          <p
                            id={`${setting}-error`}
                            className="text-xs italic text-red-500"
                          >
                            {validationMessages[setting]}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="flex flex-col mt-10">
              {/* checkbox asking the customer: when checked, if logs have errors we will continue the training wit the good ones and skip the error ones. if unchecked, we will abort the training if there are invalid logs */}
              <div className="flex items-baseline pb-4">
                <div className="pt-2">
                  <input
                    type="checkbox"
                    id="skipErrors"
                    name="skipErrors"
                    className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
                    checked={skipLogsWithError}
                    onChange={() => setSkipLogsWithError(!skipLogsWithError)}
                  />
                </div>
                <label
                  htmlFor="skipErrors"
                  className="ml-2 text-sm font-normal text-zinc-800 peer-disabled:opacity-50"
                >
                  Skip logs with errors{' '}
                  <span className="text-xs text-zinc-500">
                    (When checked, we will continue the training even if there
                    are logs with errors, skipping the invalid logs. When
                    unchecked, we will abort the training if there are invalid
                    logs)
                  </span>
                </label>
              </div>
              {estimatedCost > 0 ? (
                <div className="w-full mx-auto mb-8 md:w-11/12">
                  <div className="mt-4 text-sm text-zinc-500">
                    <EstimateTimeAndCost
                      estimatedCost={estimatedCost}
                      estimatedTime={estimatedTime}
                      logsUsed={
                        (dataInput === 'upload' &&
                          (validationInProgress ||
                            !fileContent.numberOfLogs)) ||
                        (dataInput === 'tags' && selectedTags.length === 0) ||
                        (dataInput === 'datasets' && !selectedDataset)
                          ? 0
                          : logsUsed
                      }
                      logsRequired={{
                        min: selectedBaseModel?.min_logs_required,
                        good: selectedBaseModel?.good_number_of_logs_required,
                        excellent:
                          selectedBaseModel?.excellent_number_of_logs_required,
                      }}
                      good
                    />
                  </div>
                </div>
              ) : (
                // give a message that we were unable to calculate the cost and time at this moment
                <div className="w-full p-4 mx-auto mt-4 mb-8 border rounded-md shadow text-zinc-900 md:w-11/12 border-zinc-300">
                  We are unable to estimate the cost and time required for
                  Fine-tuning for this base model at this moment.
                </div>
              )}
              <button
                type="submit"
                className="flex items-center justify-center w-full h-10 bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100"
                disabled={loading}
                aria-busy={loading}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {dataInput === 'upload' && 'Upload & '}Create
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="grow min-h-36"></div>
        <div className="max-w-4xl p-4 pb-10 mt-8 text-sm whitespace-normal text-zinc-500">
          <div>
            <span>
              <sup>*</sup>
            </span>{' '}
            The estimated cost and time are calculated based on the number of
            logs you have selected. The calculation is an estimate and the
            actual cost may vary,{' '}
            <Link
              to="/payment-policy"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Learn more.
            </Link>{' '}
          </div>
        </div>
      </div>
      <FineTuneCreateErrorModal
        showModal={showErrorModal}
        closeModal={clearError}
        errorCode={errorCode}
      />
      <InsufficientCreditsModal
        showModal={showInsufficientCreditsModal}
        closeModal={() => setShowInsufficientCreditsModal(false)}
        tailorJobEstimate={estimatedCost}
      />
    </>
  );
};

export default TailorFinetune;
