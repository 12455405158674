// import { Modal } from 'flowbite-react';
// import { useUser } from '../../UserContext';
// import { toast } from 'react-hot-toast';
// import { loadStripe } from '@stripe/stripe-js';
// import { HowBillingWorks } from '../Modals/HowPaymentWorksModal';
// import AutoTopupSettings from '../AutoTopupSettings';
// import { useState } from 'react';
// const stripe_publishable_key = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
// const stripePromise = loadStripe(stripe_publishable_key);

// /**
//  * AddCreditCardModal: A component that displays the add credit card modal
//  * @component
//  * @return {React.Component}
//  * @param {Object} props
//  * @param {boolean} props.showModal - The state of the modal
//  * @param {function} props.closeModal - The function to close the modal
//  * @param {string} props.type - The type of the modal
//  * @param {Object} props.user - The user object
//  * @example
//  * return (
//  * <AddCreditCardModal />
//  * )
//  * @see https://flowbite.com/docs/components/modal
//  * @see https://stripe.com/docs/payments/checkout
//  * @see https://stripe.com/docs/api/checkout/sessions/create
//  * @see https://stripe.com/docs/api/checkout/sessions/object
//  */

// const AddCreditCardModal = ({ showModal, closeModal, type = 'newUser' }) => {
//   const { user, customAxios } = useUser();
//   const [isSetupOk, setIsSetupOk] = useState(false);
//   let content;

//   switch (type) {
//     case 'newUser':
//       content = {
//         title: 'Payment Method Required',
//         description:
//           user?.team_role === 'admin' ? (
//             <>
//               <div>
//                 <strong>You are almost there!</strong> To continue using our
//                 services, you need to link a payment method to your account. You
//                 can also set up automatic top-ups to ensure you never run out of
//                 credits.
//               </div>
//               <br />
//               <div>
//                 {' '}
//                 We are also giving you $2 in complimentary credits to kickstart
//                 your experience.
//               </div>
//               <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
//             </>
//           ) : user?.team_role === 'member' ? (
//             <div className="pb-4">
//               We noticed the team account does not have a stored payment method.
//               Please contact your team admin to add a valid card to the account.
//             </div>
//           ) : null,
//       };
//       break;
//     case 'lowBalance':
//       content = {
//         title: 'Low Balance',
//         description:
//           user?.team_role === 'admin' ? (
//             <>
//               <div>
//                 Your account balance is running low. To continue using our
//                 services, please add more credits to your account.
//               </div>
//             </>
//           ) : user?.team_role === 'member' ? (
//             <>
//               <div>
//                 The team account balance is running low. Please contact your
//                 team admin to add more credits to the account.
//               </div>
//             </>
//           ) : null,
//       };
//       break;
//     case 'existingUser':
//       content = {
//         title: 'Payment Method Required',
//         description:
//           user?.team_role === 'admin' ? (
//             <>
//               <div className="pb-4">
//                 We noticed you do not have a stored payment method. To continue
//                 using our services, please make sure a valid card is associated
//                 with this account.
//               </div>
//               <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
//             </>
//           ) : user?.team_role === 'member' ? (
//             <>
//               <div className="pb-4">
//                 We noticed the team account does not have a stored payment
//                 method. Please contact your team admin to add a valid card to
//                 the account.
//               </div>
//             </>
//           ) : null,
//       };
//       break;
//     case 'expiredCard':
//       content = {
//         title: 'Payment Method Expired',
//         description:
//           user?.team_role === 'admin' ? (
//             <>
//               <div className="pb-4">
//                 According to our records, the payment method associated to this
//                 account has expired. Please add a new card to ensure
//                 uninterrupted access to your machine rentals.
//               </div>
//               <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
//             </>
//           ) : user?.team_role === 'member' ? (
//             <>
//               <div className="pb-4">
//                 According to our records, the payment method associated to the
//                 team account has expired. Please contact your team admin to add
//                 a new card to ensure uninterrupted access to machine rentals.
//               </div>
//             </>
//           ) : null,
//       };
//       break;
//     case 'noCard':
//       content = {
//         title: 'Payment Method Required',
//         description:
//           user?.team_role === 'admin' ? (
//             <>
//               <div className="pb-4">
//                 We noticed you do not have a stored payment method. To continue
//                 using our services, please make sure a valid card is associated
//                 with this account.
//               </div>
//               <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
//             </>
//           ) : user?.team_role === 'member' ? (
//             <>
//               <div className="pb-4">
//                 We noticed the team account does not have a stored payment
//                 method. Please contact your team admin to add a valid card to
//                 the account.
//               </div>
//             </>
//           ) : null,
//       };
//       break;
//     case 'rejectedCard':
//       content = {
//         title: 'Payment Method Rejected',
//         description:
//           user?.team_role === 'admin' ? (
//             <>
//               <div className="pb-4">
//                 We were unable to process the payment method associated with
//                 this account. Please check that you have enough funds in your
//                 account or add a new card to ensure a seamless experience.
//               </div>
//               <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
//             </>
//           ) : user?.team_role === 'member' ? (
//             <>
//               <div className="pb-4">
//                 We were unable to process the payment method associated with the
//                 team account. Please contact your team admin to resolve the
//                 issue.
//               </div>
//             </>
//           ) : null,
//       };
//       break;
//     default:
//       content = {
//         title: 'Payment Method Required',
//         description:
//           user?.team_role === 'admin' ? (
//             <>
//               <div className="pb-4">
//                 We noticed you do not have a stored payment method. To continue
//                 using our services, please make sure a valid card is associated
//                 with this account.
//               </div>
//               <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
//             </>
//           ) : user?.team_role === 'member' ? (
//             <>
//               <div className="pb-4">
//                 We noticed the team account does not have a stored payment
//                 method. Please contact your team admin to add a valid card to
//                 the account.
//               </div>
//             </>
//           ) : null,
//       };
//   }

//   const handleCheckout = async () => {
//     const stripe = await stripePromise;
//     try {
//       const response = await customAxios.post('create-session', {});
//       const session = response.data;
//       await stripe.redirectToCheckout({
//         sessionId: session.session_id,
//       });
//       closeModal();
//     } catch (error) {
//       console.error('Error during checkout:', error);
//       if (error.code === 'ERR_NETWORK') {
//         toast.error('Network error. Please try again later.', { id: 'network-error'});
//         return;
//       }
//       toast.error('Error redirecting to checkout');
//     }
//   };
//   return (
//     <Modal show={showModal} onClose={closeModal} size="2xl" dismissible>
//       <Modal.Header>
//         <p className="flex gap-4 items-base">
//           {' '}
//           <svg
//             className="w-6 h-6 text-indigo-600 dark:text-white"
//             aria-hidden="true"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="currentColor"
//             viewBox="0 0 24 24"
//           >
//             <path
//               fillRule="evenodd"
//               d="M4 5a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z"
//               clipRule="evenodd"
//             />
//             <path
//               fillRule="evenodd"
//               d="M5 14c0-.6.4-1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0c0-.6.4-1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z"
//               clipRule="evenodd"
//             />
//           </svg>
//           {content.title}
//         </p>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="mx-4 ">
//           <div className="font-sans text-gray-600 dark:text-gray-200">
//             {content.description}
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         {user?.team_role === 'admin' && (
//           <div className="flex w-full">
//             <button
//               data-testid="add-credit-card-button"
//               className="items-center justify-center w-1/2 py-2 mx-auto bg-gray-900 rounded-md shadow-lg font-spaceG hover:shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
//               onClick={handleCheckout}
//               disabled={!isSetupOk}
//             >
//               <div className="flex flex-col items-center justify-center my-auto sm:flex-row">
//                 <div className="flex font-semibold text-white whitespace-nowrap">
//                   Continue with{' '}
//                 </div>
//                 <div>
//                   <img className="w-16 " src="/StripeLogo.svg" />
//                 </div>
//               </div>
//             </button>
//           </div>
//         )}
//         {user?.team_role === 'member' && (
//           <div className="flex w-full">
//             <button
//               className="items-center justify-center w-1/2 py-2 mx-auto bg-gray-900 rounded-md shadow-lg font-spaceG hover:shadow-sm hover:bg-gray-700"
//               onClick={closeModal}
//             >
//               <div className="flex flex-col items-center justify-center my-auto sm:flex-row">
//                 <div className="flex font-semibold text-white whitespace-nowrap">
//                   Close
//                 </div>
//               </div>
//             </button>
//           </div>
//         )}
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default AddCreditCardModal;

import { Modal } from 'flowbite-react';
import { toast } from 'react-hot-toast';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';

import { useUser } from '../../UserContext';
import { HowBillingWorks } from '../Modals/HowPaymentWorksModal';
import AutoTopupSettings from '../AutoTopupSettings';

const stripe_publishable_key = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripe_publishable_key);

const getContent = (type, user, setIsSetupOk) => {
  switch (type) {
    case 'newUser':
      return {
        title: 'Payment Method Required',
        description:
          user?.team_role === 'admin' ? (
            <>
              <div>
                <strong>You are almost there!</strong> To continue using our
                services, you need to link a payment method to your account. You
                can also set up automatic top-ups to ensure you never run out of
                credits.
              </div>
              <br />
              <div>
                We are also giving you $2 in complimentary credits to kickstart
                your experience.
              </div>
              <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
            </>
          ) : (
            <div className="pb-4">
              We noticed the team account does not have a stored payment method.
              Please contact your team admin to add a valid card to the account.
            </div>
          ),
      };
    case 'lowBalance':
      return {
        title: 'Low Balance',
        description:
          user?.team_role === 'admin' ? (
            <div>
              Your account balance is running low. To continue using our
              services, please add more credits to your account.
            </div>
          ) : (
            <div>
              The team account balance is running low. Please contact your team
              admin to add more credits to the account.
            </div>
          ),
      };
    case 'existingUser':
      return {
        title: 'Payment Method Required',
        description:
          user?.team_role === 'admin' ? (
            <>
              <div className="pb-4">
                We noticed you do not have a stored payment method. To continue
                using our services, please make sure a valid card is associated
                with this account.
              </div>
              <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
            </>
          ) : (
            <div className="pb-4">
              We noticed the team account does not have a stored payment method.
              Please contact your team admin to add a valid card to the account.
            </div>
          ),
      };
    case 'expiredCard':
      return {
        title: 'Payment Method Expired',
        description:
          user?.team_role === 'admin' ? (
            <>
              <div className="pb-4">
                According to our records, the payment method associated with
                this account has expired. Please add a new card to ensure
                uninterrupted access to your machine rentals.
              </div>
              <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
            </>
          ) : (
            <div className="pb-4">
              According to our records, the payment method associated with the
              team account has expired. Please contact your team admin to add a
              new card to ensure uninterrupted access to machine rentals.
            </div>
          ),
      };
    case 'noCard':
      return {
        title: 'Payment Method Required',
        description:
          user?.team_role === 'admin' ? (
            <>
              <div className="pb-4">
                We noticed you do not have a stored payment method. To continue
                using our services, please make sure a valid card is associated
                with this account.
              </div>
              <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
            </>
          ) : (
            <div className="pb-4">
              We noticed the team account does not have a stored payment method.
              Please contact your team admin to add a valid card to the account.
            </div>
          ),
      };
    case 'rejectedCard':
      return {
        title: 'Payment Method Rejected',
        description:
          user?.team_role === 'admin' ? (
            <>
              <div className="pb-4">
                We were unable to process the payment method associated with
                this account. Please check that you have enough funds in your
                account or add a new card to ensure a seamless experience.
              </div>
              <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
            </>
          ) : (
            <div className="pb-4">
              We were unable to process the payment method associated with the
              team account. Please contact your team admin to resolve the issue.
            </div>
          ),
      };
    default:
      return {
        title: 'Payment Method Required',
        description:
          user?.team_role === 'admin' ? (
            <>
              <div className="pb-4">
                We noticed you do not have a stored payment method. To continue
                using our services, please make sure a valid card is associated
                with this account.
              </div>
              <AutoTopupSettings setIsSetupOk={setIsSetupOk} />
            </>
          ) : (
            <div className="pb-4">
              We noticed the team account does not have a stored payment method.
              Please contact your team admin to add a valid card to the account.
            </div>
          ),
      };
  }
};

const CheckoutButton = ({ handleCheckout, isSetupOk }) => (
  <button
    data-testid="add-credit-card-button"
    className="items-center justify-center w-1/2 py-2 mx-auto bg-gray-900 rounded-md shadow-lg font-spaceG hover:shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
    onClick={handleCheckout}
    disabled={!isSetupOk}
  >
    <div className="flex flex-col items-center justify-center my-auto sm:flex-row">
      <div className="flex font-semibold text-white whitespace-nowrap">
        Continue with{' '}
      </div>
      <div>
        <img className="w-16 " src="/StripeLogo.svg" />
      </div>
    </div>
  </button>
);

const AddCreditCardModal = ({ showModal, closeModal, type = 'newUser' }) => {
  const { user, customAxios } = useUser();
  const [isSetupOk, setIsSetupOk] = useState(false);
  const content = getContent(type, user, setIsSetupOk);

  const handleCheckout = async () => {
    const stripe = await stripePromise;
    try {
      const response = await customAxios.post('create-session', {});
      const session = response.data;
      await stripe.redirectToCheckout({
        sessionId: session.session_id,
      });
      closeModal();
    } catch (error) {
      console.error('Error during checkout:', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
      toast.error('Error redirecting to checkout');
    }
  };

  return (
    <Modal show={showModal} onClose={closeModal} size="2xl" dismissible>
      <Modal.Header>
        <p className="flex gap-4 items-base">
          <svg
            className="w-6 h-6 text-indigo-600 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M4 5a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M5 14c0-.6.4-1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0c0-.6.4-1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z"
              clipRule="evenodd"
            />
          </svg>
          {content.title}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-4 ">
          <div className="font-sans text-gray-600 dark:text-gray-200">
            {content.description}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {user?.team_role === 'admin' && (
          <CheckoutButton
            handleCheckout={handleCheckout}
            isSetupOk={isSetupOk}
          />
        )}
        {user?.team_role === 'member' && (
          <div className="flex w-full">
            <button
              className="items-center justify-center w-1/2 py-2 mx-auto bg-gray-900 rounded-md shadow-lg font-spaceG hover:shadow-sm hover:bg-gray-700"
              onClick={closeModal}
            >
              <div className="flex flex-col items-center justify-center my-auto sm:flex-row">
                <div className="flex font-semibold text-white whitespace-nowrap">
                  Close
                </div>
              </div>
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddCreditCardModal;
