// MonthPicker.jsx

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default function MonthPicker({ setDate, currentDate }) {
  const { thisMonth } = currentDate;
  const { month: selectedMonth, year } = thisMonth;
  const dropdownRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const navigateMonth = (direction) => {
    let newMonth = selectedMonth + direction;
    let newYear = year;

    if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    } else if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    }

    const newThisMonth = { month: newMonth, year: newYear };
    const newPreviousMonth = {
      month: newMonth - 1 < 0 ? 11 : newMonth - 1,
      year: newMonth - 1 < 0 ? newYear - 1 : newYear,
    };

    setDate({ thisMonth: newThisMonth, previousMonth: newPreviousMonth });
  };

  const navigateYear = (direction, e) => {
    e.stopPropagation();
    const newYear = year + direction;

    const newThisMonth = { month: selectedMonth, year: newYear };
    const newPreviousMonth = {
      month: selectedMonth - 1 < 0 ? 11 : selectedMonth - 1,
      year: selectedMonth - 1 < 0 ? newYear - 1 : newYear,
    };

    setDate({ thisMonth: newThisMonth, previousMonth: newPreviousMonth });
  };

  const toggleDropdown = () => {
    setIsVisible(!isVisible);
    if (!isVisible) {
      setTimeout(() => dropdownRef.current?.focus(), 100);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsVisible(false);
    }
    if (event.key === 'ArrowLeft') {
      navigateMonth(-1);
    }
    if (event.key === 'ArrowRight') {
      navigateMonth(1);
    }
    if (event.key === 'Enter') {
      setIsVisible(false);
    }
    if (event.key === 'ArrowDown') {
      const nextIndex = selectedMonth + 3;
      if (nextIndex < months.length) {
        const newMonth = nextIndex;
        const newThisMonth = { month: newMonth, year: year };
        const newPreviousMonth = {
          month: newMonth - 1 < 0 ? 11 : newMonth - 1,
          year: newMonth - 1 < 0 ? year - 1 : year,
        };
        setDate({ thisMonth: newThisMonth, previousMonth: newPreviousMonth });
      }
    }
    if (event.key === 'ArrowUp') {
      const nextIndex = selectedMonth - 3;
      if (nextIndex >= 0) {
        const newMonth = nextIndex;
        const newThisMonth = { month: newMonth, year: year };
        const newPreviousMonth = {
          month: newMonth - 1 < 0 ? 11 : newMonth - 1,
          year: newMonth - 1 < 0 ? year - 1 : year,
        };
        setDate({ thisMonth: newThisMonth, previousMonth: newPreviousMonth });
      }
    }
  };

  const selectMonth = (monthIndex) => {
    const newThisMonth = { month: monthIndex, year: year };
    const newPreviousMonth = {
      month: monthIndex - 1 < 0 ? 11 : monthIndex - 1,
      year: monthIndex - 1 < 0 ? year - 1 : year,
    };
    setDate({ thisMonth: newThisMonth, previousMonth: newPreviousMonth });
    setIsVisible(false);
  };

  return (
    <div className="relative z-10" onKeyDown={handleKeyDown}>
      <div
        className="flex items-center justify-between w-64 mx-auto transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-500"
        onClick={toggleDropdown}
      >
        <button
          className="flex items-center justify-around w-12 h-10"
          onClick={(e) => {
            e.stopPropagation();
            navigateMonth(-1);
          }}
        >
          <ChevronLeftIcon
            className="w-5 h-5 cursor-pointer"
            aria-label="Previous month"
          />
        </button>

        <span
          className="flex items-center justify-around flex-1 h-10 font-medium text-zinc-800"
          aria-hidden="true"
        >{`${months[selectedMonth]} ${year}`}</span>

        <button
          className="flex items-center justify-around w-12 h-10"
          onClick={(e) => {
            e.stopPropagation();
            navigateMonth(1);
          }}
        >
          <ChevronRightIcon
            className="w-5 h-5 cursor-pointer"
            aria-label="Next month"
          />
        </button>
      </div>
      {isVisible && (
        <div
          ref={dropdownRef}
          tabIndex={-1}
          className="absolute w-full p-4 mt-1 transition duration-300 ease-out transform translate-y-1 bg-white rounded-md shadow-lg opacity-100"
          style={{ animation: 'slideDown 0.3s ease-out' }}
          aria-label="Month picker"
        >
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              className="w-5 h-5 ml-2 cursor-pointer"
              onClick={(e) => navigateYear(-1, e)}
              aria-label="Previous year"
            />
            <span className="font-semibold">{year}</span>
            <ChevronRightIcon
              className="w-5 h-5 mr-2 cursor-pointer"
              onClick={(e) => navigateYear(1, e)}
              aria-label="Next year"
            />
          </div>
          <div className="grid grid-cols-3 gap-4 mt-2">
            {months.map((month, index) => (
              <div
                key={month}
                className={`rounded-md px-2 py-1 text-center ${
                  index === selectedMonth
                    ? 'bg-indigo-500 text-white'
                    : 'hover:bg-indigo-100 cursor-pointer'
                }`}
                onClick={() => selectMonth(index)}
                role="option"
                aria-selected={index === selectedMonth}
                tabIndex={0}
              >
                {month}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
