import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Dropdown, Tooltip, Modal } from 'flowbite-react';
import { toast } from 'react-hot-toast';
import { Switch } from '@headlessui/react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { useUser } from '../UserContext';
import DashboardSalesChart from '../Components/DashboardSalesChart';
import ActionModal from '../Components/Modals/ActionModal';
import UnlistListingModal from '../Components/Modals/UnlistListingModal';
import ConfigureListingModal from '../Components/Modals/ConfigureListingModal';
import EmailModal from '../Components/Modals/EmailModal';
import { useCurrencyConverter } from '../Hooks/useCurrencyConverter';
import Spinner from '../Components/Spinner';
import ProviderColumnChart from '../Components/ProviderColumnChart';

const NewProviderDashboard = () => {
  document.documentElement.classList.add('dark');

  const { user, customAxios, checkTokenExpiration, setShowTokenExpiredModal } =
    useUser();
  const money = useCurrencyConverter();
  const [dashboardData, setDashboardData] = useState({
    amountOwed: 0,
    delinquent: false,
    instances: [],
    totalCosts: 0,
    totalPaid: 0,
  });
  const [chartData, setChartData] = useState({ series: [], categories: [] });
  const [showActionModal, setShowActionModal] = useState(false);
  const [availModal, setAvailModal] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [listings, setListings] = useState([]);
  const [displayedListings, setDisplayedListings] = useState([]);

  const [availableSwitch, setAvailableSwitch] = useState(listings.available);
  const [sortOrder, setSortOrder] = useState('Rented');
  const [financialDetails, setFinancialDetails] = useState({
    totalOwed: 0,
    totalEarned: 0,
    totalPaid: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historicInstances, setHistoricInstances] = useState([]);
  const [selectedListings, setSelectedListings] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);
  const userEmail = localStorage.getItem('email') || '';
  const [clusters, setClusters] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [newPriceInput, setNewPriceInput] = useState('');
  const [selectedAvailability, setSelectedAvailability] = useState('available');
  const [totalEarned, setTotalEarned] = useState(0);

  const handleConfigureListing = () => {
    const isTokenExpired = checkTokenExpiration();
    if (isTokenExpired) {
      setShowTokenExpiredModal(true);
    } else {
      setIsModalOpen(true);
    }
  };

  // multi edit
  const [isMultiPriceModalOpen, setIsMultiPriceModalOpen] = useState(false);
  const [priceChangePercentage, setPriceChangePercentage] = useState(0);
  const [isIncrease, setIsIncrease] = useState(true); // true for increase, false for decrease

  // multi edit

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // checkbox--------------

  useEffect(() => {
    const allNodes = {};
    clusters.forEach((cluster) => {
      cluster.nodes.forEach((node) => {
        allNodes[node.id] = false;
      });
    });
    setSelectedNodes(allNodes);
  }, [clusters]);

  //Toggle Available----------------------------

  // const toggleListingAvailability = async (listingId, currentAvailable) => {
  //   const newAvailable = !currentAvailable;

  //   try {
  //     // TODO: ** return the updated listing from the BE, and update the state with it
  //     await customAxios.post(`listings/${listingId}`, {
  //       available: newAvailable,
  //     });

  //     // Assuming the response contains the updated listing
  //     // Update the state to reflect these changes
  //     setListings((prevListings) =>
  //       prevListings.map((listing) =>
  //         listing.id === listingId
  //           ? { ...listing, available: newAvailable }
  //           : listing,
  //       ),
  //     );
  //     toast.success('Listing availability updated successfully');
  //   } catch (error) {
  //     console.error('Error updating listing:', error);
  //     if (error.code === 'ERR_NETWORK') {
  //       toast.error('Network error. Please try again later.', { id: 'network-error'});
  //       return;
  //     }
  //     toast.error('Error updating listing, please try again later');
  //   }
  // };

  //   const toggleSortOrder = () => {
  //     if (sortOrder === 'Rented') setSortOrder('Available');
  //     else if (sortOrder === 'Available') setSortOrder('Unavailable');
  //     else setSortOrder('Rented');
  //   };

  const combinedVariants = {
    initial: {
      // Properties from containerVariants' initial state
      backgroundColor: 'rgba(17, 24, 39, 0)',
      boxShadow: 'none',
      // Properties from dashboardVariants' hidden state
      y: 20,
      opacity: 0,
    },
    animate: {
      // Properties from containerVariants' animate state
      backgroundColor: '#111827',
      boxShadow:
        '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)',
      // Properties from dashboardVariants' visible state
      y: 0,
      opacity: 1,
      transition: {
        // Merge the transition properties. Adjust as needed.
        duration: 1,
        delay: 0.5,
      },
    },
  };

  useEffect(() => {
    const fetchCluster = async () => {
      try {
        const response = await customAxios.get('clusters');
        setClusters(response.data.clusters);
      } catch (error) {
        console.error('Error retrieving cluster data:', error);
        if (error.code === 'ERR_NETWORK') {
          toast.error('Network error. Please try again later.', { id: 'network-error'});
          return;
        }
        toast.error('Error retrieving cluster data, please try again later');
      }
    };
    fetchCluster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processDataForChart = (instances) => {
    const costByDate = instances.reduce((acc, instance) => {
      // If 'ended_at' is null, use a default date (e.g., current date)
      const endDate = instance.ended_at ? moment(instance.ended_at) : moment();
      const dateKey = endDate.format('YYYY-MM-DD');

      // Include instance cost if greater than zero
      if (instance.cost > 0) {
        acc[dateKey] = (acc[dateKey] || 0) + instance.cost * 0.85;
      }
      return acc;
    }, {});

    // Sort dates and prepare series data
    const sortedDates = Object.keys(costByDate).sort(
      (a, b) => new Date(a) - new Date(b),
    );
    const seriesData = sortedDates.map((date) => costByDate[date]);

    return {
      series: [{ name: 'Income', data: seriesData, color: 'gray' }],
      categories: sortedDates,
    };
  };

  useEffect(() => {
    const processedData = processDataForChart(historicInstances);
    setChartData(processedData);
  }, [historicInstances]);

  const createListingData = (listing) => {
    const data = [
      { title: 'Host', value: listing.host },
      { title: 'OS', value: listing.os_name },
      {
        title: 'CPU',
        value: `${listing.cpu_name.split('_').join(' ')}  (${listing.number_of_physical_cores} physical cores, ${listing.total_number_of_cores} total cores)`,
      },
      {
        title: 'GPU',
        value: `${listing.number_of_gpus} X ${listing.gpu_name} (${listing.gpu_memory} GB)`,
      },
      {
        title: 'GPU Driver version',
        value: listing.driver_version,
      },
      {
        title: 'Host <-> GPU Bandwidth',
        value: `${listing.pcie_bandwidth} GB/s (Gen ${listing.pcie_generation} x${listing.pcie_lanes_per_gpu})`,
      },
      {
        title: 'RAM',
        value: `${listing.host_ram} GB (${listing.host_swap} GB Swap)`,
      },
      { title: 'Disk size', value: `${listing.disk_size} GB` },
      // { title: 'Ports', value: listing.ports },
      {
        title: 'Network Speed',
        value: `↓ ${Number(listing.network_download_speed).toFixed(
          1,
        )} Mb/s, ↑ ${Number(listing.network_upload_speed).toFixed(1)} Mb/s`,
      },
    ];

    const modifiedData = data.map((item) => {
      const value =
        typeof item.value === 'string' ? item.value : String(item.value);
      return {
        ...item,
        value: value.startsWith('-') ? '-' : value,
      };
    });

    if (listing.gpu_platform === null) {
      listing.gpu_platform = 'NVIDIA';
    }

    return modifiedData;
  };

  const accessibleListings = listings.filter(
    (listing) => listing.available && listing.accessible,
  );

  const utilPercent =
    (accessibleListings.filter((listing) => listing.instances.length > 0)
      .length /
      accessibleListings.length) *
    100;

  // table sorting---------------------------------------------
  const [selectedGPUs, setSelectedGPUs] = useState('');
  const [selectedPriceRange, setSelectedPriceRange] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('allOnline');

  const handleGPUsChange = (e) => {
    setSelectedGPUs(e.target.value);
  };

  const handlePriceRangeChange = (e) => {
    setSelectedPriceRange(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  // Define the status order for sorting
  const statusOrder = ['Rented', 'Available', 'Unavailable'];

  // Sorting function
  const sortListings = (a, b) => {
    const aStatus =
      a.instances.length > 0
        ? 'Rented'
        : a.available && a.accessible
          ? 'Available'
          : 'Unavailable';
    const bStatus =
      b.instances.length > 0
        ? 'Rented'
        : b.available && b.accessible
          ? 'Available'
          : 'Unavailable';

    return statusOrder.indexOf(aStatus) - statusOrder.indexOf(bStatus);
  };

  // Combined filtered and sorted listings
  const sortedAndFilteredListings = useMemo(() => {
    const sortedAndFiltered = listings
      .filter((listing) => {
        const matchesGPUs = selectedGPUs
          ? listing.number_of_gpus === parseInt(selectedGPUs)
          : true;
        const matchesPrice = selectedPriceRange
          ? (() => {
              let minPrice, maxPrice;
              switch (selectedPriceRange) {
                case 'below-1':
                  minPrice = 0;
                  maxPrice = 1;
                  break;
                case 'below-2':
                  minPrice = 1;
                  maxPrice = 2;
                  break;
                case 'below-4':
                  minPrice = 3;
                  maxPrice = 4;
                  break;
                case 'over-5':
                  return listing.price >= 5;
                default:
                  return true;
              }
              return listing.price >= minPrice && listing.price < maxPrice;
            })()
          : true;
        const matchesStatus = selectedStatus
          ? (() => {
              switch (selectedStatus) {
                case 'allOnline':
                  return (
                    listing.instances.length > 0 ||
                    (listing.available &&
                      listing.accessible &&
                      listing.instances.length === 0)
                  );
                case 'active':
                  return listing.instances.length > 0; // logic for 'active'
                case 'available':
                  return (
                    listing.available &&
                    listing.accessible &&
                    listing.instances.length === 0
                  ); // logic for 'available'
                case 'unavailable':
                  return !listing.available || !listing.accessible; // logic for 'unavailable'

                default:
                  return true;
              }
            })()
          : true;

        return matchesGPUs && matchesPrice && matchesStatus;
      })
      .sort(sortListings);

    setDisplayedListings(sortedAndFiltered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listings, selectedGPUs, selectedPriceRange, selectedStatus]);

  // Function to toggle row visibility
  const toggleRow = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  // table sorting---------------------------------------------

  // multi edit----------------------------------------------

  const openAvailModal = () => {
    setAvailModal(true);
  };

  const openModal = (increase) => {
    // console.log('Opening modal with increase:', increase);
    setIsIncrease(increase);
    setIsMultiPriceModalOpen(true);
  };
  const confirmPriceChange = async () => {
    const newPriceCents = parseFloat(newPriceInput) * 100; // Convert dollars to cents

    const updatePromises = Object.entries(selectedNodes)
      .filter(([id, isSelected]) => isSelected)
      .map(([id]) => {
        const node = clusters
          .flatMap((cluster) => cluster.nodes)
          .find((node) => node.id.toString() === id);
        if (!node) {
          return Promise.resolve({
            error: new Error(`Node not found for ID: ${id}`),
          });
        }

        const payload = {
          price: newPriceCents,
        };

        if (import.meta.env.DEV) {
          console.log('Payload:', payload);
        }

        return customAxios
          .patch(`/nodes/${id}`, payload)
          .then((response) => ({ result: response.data }))
          .catch((error) => ({ error }));
      });

    await Promise.all(updatePromises).then((results) => {
      const errors = results.filter((r) => r.error);
      if (errors.length > 0) {
        console.error(
          'Errors occurred during update:',
          errors.map((e) => e.error.message),
        );
        toast.error('Error updating node prices, please try again later');
      } else {
        toast.success('Node prices updated successfully');
        setClusters((currentClusters) =>
          currentClusters.map((cluster) => ({
            ...cluster,
            nodes: cluster.nodes.map((node) => ({
              ...node,
              price_per_gpu: selectedNodes[node.id]
                ? newPriceCents
                : node.price_per_gpu,
            })),
          })),
        );
      }
    });

    setIsMultiPriceModalOpen(false);
  };

  const confirmAvailChange = async (newAvailability) => {
    const updatePromises = Object.entries(selectedNodes)
      .filter(([id, isSelected]) => isSelected)
      .map(([id]) => {
        const node = clusters
          .flatMap((cluster) => cluster.nodes)
          .find((node) => node.id.toString() === id);
        if (!node) {
          return Promise.resolve({
            error: new Error(`Node not found for ID: ${id}`),
          });
        }

        const payload = {
          availability: newAvailability, // This should be either 'available' or 'unavailable'
        };

        if (import.meta.env.DEV) {
          console.log('Payload:', payload);
        }

        return customAxios
          .patch(`/nodes/${id}`, payload)
          .then((response) => ({ result: response.data }))
          .catch((error) => ({ error }));
      });

    await Promise.all(updatePromises).then((results) => {
      const errors = results.filter((r) => r.error);
      if (errors.length > 0) {
        console.error(
          'Errors occurred during update:',
          errors.map((e) => e.error.message),
        );
        toast.error('Error updating node availability, please try again later');
      } else {
        toast.success('Node availability updated successfully');
        setClusters((currentClusters) =>
          currentClusters.map((cluster) => ({
            ...cluster,
            nodes: cluster.nodes.map((node) => ({
              ...node,
              availability: selectedNodes[node.id]
                ? newAvailability
                : node.availability,
            })),
          })),
        );
      }
    });

    setAvailModal(false);
  };

  // multi edit----------------------------------------------

  const handleToggleEmailModal = () => {
    setShowEmailModal((prevState) => !prevState);
  };

  function formatGpuName(gpuName) {
    const prefix = 'NVIDIA';
    if (gpuName.startsWith(prefix)) {
      const newName = gpuName.slice(prefix.length).trim();
      return newName.includes(prefix) ? newName : prefix + ' ' + newName;
    }
    return gpuName;
  }

  const hasPods = clusters.some((cluster) =>
    cluster.nodes.some((node) => node.pods && node.pods.length > 0),
  );

  const calculateTotalGPUs = (clusters) => {
    let totalGPUs = 0;
    let totalRentedGPUs = 0;

    // Loop through each cluster
    clusters.forEach((cluster) => {
      // Loop through each node within the cluster
      cluster.nodes.forEach((node) => {
        // Add the gpu_count of each node to the total
        totalGPUs += parseInt(node.gpu_count);

        // Sum up GPU count for pods in 'ready' state
        node.pods.forEach((pod) => {
          if (pod.state === 'ready') {
            totalRentedGPUs += parseInt(pod.gpu_count);
          }
        });
      });
    });

    return { totalGPUs, totalRentedGPUs };
  };

  // Example usage:
  const { totalGPUs, totalRentedGPUs } = calculateTotalGPUs(clusters);

  const ClusterTable = ({
    clusters,
    selectedNode,
    setSelectedNode,
    selectedNodes,
    setSelectedNodes,
    selectAll,
    setSelectAll,
  }) => {
    function classNames(...classes) {
      return classes.filter(Boolean).join(' ');
    }

    // const [selectedNode, setSelectedNode] = useState(null);
    // const [selectedNodes, setSelectedNodes] = useState({});
    // const [selectAll, setSelectAll] = useState(false);
    const [switchStates, setSwitchStates] = useState({});

    // Initialize switch states based on node availability when component mounts or clusters data changes
    useEffect(() => {
      const initialSwitchStates = {};
      clusters.forEach((cluster) => {
        cluster.nodes.forEach((node) => {
          initialSwitchStates[node.id] = node.availability === 'available';
        });
      });
      setSwitchStates(initialSwitchStates);
    }, [clusters]);

    const toggleNode = (nodeId) => {
      setSelectedNode(selectedNode === nodeId ? null : nodeId);
    };

    const handleSwitchChange = (nodeId) => {
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [nodeId]: !prevStates[nodeId],
      }));
    };

    const handleCheckboxChange = (nodeId) => {
      const updatedSelectedNodes = {
        ...selectedNodes,
        [nodeId]: !selectedNodes[nodeId],
      };
      setSelectedNodes(updatedSelectedNodes);
      setSelectAll(Object.values(updatedSelectedNodes).every(Boolean));
    };

    const handleSelectAllChange = () => {
      const newState = !selectAll;
      const updatedSelectedNodes = {};
      clusters.forEach((cluster) => {
        cluster.nodes.forEach((node) => {
          updatedSelectedNodes[node.id] = newState;
        });
      });
      setSelectedNodes(updatedSelectedNodes);
      setSelectAll(newState);
    };

    return (
      <table className="min-w-full divide-y divide-gray-600">
        <thead className="bg-gray-950">
          <tr>
            <th scope="col" className="w-1 px-6 py-3">
              <input
                id="selectAll"
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
                className="w-4 h-4 text-indigo-600 bg-gray-800 border-gray-300 rounded focus:ring-indigo-600"
              />
            </th>
            <th scope="col" className="relative w-1 px-6 py-3">
              <span className="sr-only">Expand</span>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-400 uppercase"
            >
              Node ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-400 uppercase"
            >
              GPU Count
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-400 uppercase"
            >
              GPU Type
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-400 uppercase"
            >
              Price (gpu)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-400 uppercase"
            >
              Accessible
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-400 uppercase"
            >
              Available
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-600 bg-gray-950">
          {clusters.map((cluster) =>
            cluster.nodes.map((node) => (
              <React.Fragment key={node.id}>
                <tr
                  onClick={() => toggleNode(node.id)}
                  className="cursor-pointer hover:bg-gray-900"
                >
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={selectedNodes[node.id] || false}
                      onChange={() => handleCheckboxChange(node.id)}
                      onClick={(e) => e.stopPropagation()}
                      className="w-4 h-4 text-indigo-600 bg-gray-800 border-gray-300 rounded focus:ring-indigo-600"
                    />
                  </td>
                  <td className="w-1 px-6 py-4 whitespace-nowrap">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-5 w-5 transform text-gray-400 ${selectedNode === node.id ? 'rotate-180' : ''}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-400 whitespace-nowrap">
                    {node.id}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-400 whitespace-nowrap">
                    {node.gpu_count}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-400 whitespace-nowrap">
                    {node.normalized_gpu_type}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-400 whitespace-nowrap">
                    {money(node.price_per_gpu.toFixed(2))}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-400 whitespace-nowrap">
                    {node.accessibility === 'accessible' ? 'Yes' : 'No'}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-400 whitespace-nowrap">
                    {node.availability === 'available' ? 'Yes' : 'No'}
                    {/* <Switch
                        checked={switchStates[node.id] || false}
                        onChange={() => handleSwitchChange(node.id)}
                        onClick={(e) => e.stopPropagation()}
                        disbaled={node.pods.some(
                          (pod) => pod.state === 'ready',
                        )}
                        className="relative inline-flex items-center justify-center flex-shrink-0 h-5 rounded-full cursor-pointer w-11 group focus:outline-none "
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          className={classNames(
                            switchStates[node.id]
                              ? 'bg-indigo-600'
                              : 'bg-gray-200',
                            'absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
                          )}
                        ></span>
                        <span
                          className={classNames(
                            switchStates[node.id]
                              ? 'translate-x-5'
                              : 'translate-x-0',
                            'absolute left-1 h-5 w-5 transform rounded-full bg-gray-300 shadow ring-0 transition-transform ease-in-out duration-200',
                          )}
                        ></span>
                      </Switch> */}
                  </td>
                </tr>
                {selectedNode === node.id && (
                  <tr>
                    <td
                      colSpan="8"
                      className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap"
                    >
                      <div className="mb-2 font-bold">Active Rentals:</div>
                      {node.pods.some((pod) => pod.state === 'ready') ? (
                        <table className="w-full text-sm text-gray-500 border-b border-indigo-600">
                          <thead>
                            <tr className="border-t border-indigo-600 ">
                              <th className="px-4 py-2 text-left">Rental ID</th>
                              <th className="px-4 py-2 text-left">GPU Count</th>
                              <th className="px-4 py-2 text-left">Earnings</th>
                            </tr>
                          </thead>
                          <tbody>
                            {node.pods
                              .filter((pod) => pod.state === 'ready')
                              .map((pod) => (
                                <tr
                                  key={pod.id}
                                  className="text-gray-300 hover:bg-gray-800"
                                >
                                  <td className="px-4 py-2">{pod.id}</td>
                                  <td className="px-4 py-2">{pod.gpu_count}</td>
                                  <td className="px-4 py-2">
                                    {money(pod.total_cost * 0.85)}
                                  </td>
                                </tr>
                              ))}
                            <tr className="bg-gray-900">
                              <td className="px-4 py-2 font-semibold">
                                Total Rented GPUs:
                              </td>
                              <td
                                className="px-4 py-2 font-semibold"
                                colSpan="2"
                              >
                                {node.pods
                                  .filter((pod) => pod.state === 'ready')
                                  .reduce(
                                    (acc, pod) => acc + Number(pod.gpu_count),
                                    0,
                                  )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <div className="py-4 text-center">
                          <p>No active rentals.</p>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )),
          )}
        </tbody>
      </table>
    );
  };

  const PodDetailsComponent = ({ clusters }) => {
    const sortedPods = clusters
      .flatMap((cluster) => cluster.nodes.flatMap((node) => node.pods))
      .sort((a, b) => a.id - b.id);

    useEffect(() => {
      const total = sortedPods.reduce(
        (acc, pod) => acc + pod.total_cost * 0.85,
        0,
      );
      setTotalEarned(total);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedPods, setTotalEarned]);

    return (
      <div className="overflow-x-auto">
        <table className="w-full font-sans text-sm text-left text-gray-400 dark:text-gray-400">
          <thead className="text-xs text-gray-100 uppercase bg-gray-800">
            <tr>
              <th scope="col" className="px-4 py-2">
                Rental ID
              </th>
              <th scope="col" className="px-4 py-2">
                Created at
              </th>
              <th scope="col" className="px-4 py-2">
                Ended at
              </th>
              <th scope="col" className="px-4 py-2">
                Num. of GPUs
              </th>
              <th scope="col" className="px-4 py-2">
                Earnings
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedPods.map((pod) => (
              <tr key={pod.id} className="hover:bg-gray-900">
                <td className="px-4 py-2 text-gray-400">{pod.id}</td>
                <td className="px-4 py-2 text-gray-400">
                  {new Date(pod.created_at).toLocaleString()}
                </td>
                <td className="px-4 py-2 text-gray-400">
                  {pod.ended_at
                    ? new Date(pod.ended_at).toLocaleString()
                    : 'N/A'}
                </td>
                <td className="px-4 py-2 text-gray-400">{pod.gpu_count}</td>
                <td className="px-4 py-2 text-gray-400">
                  {money(pod.total_cost * 0.85)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div
      className="py-16 bg-gray-950"
      style={{ minHeight: 'calc(100vh - 82px)' }}
    >
      <div>
        {/* whole dashboard  */}
        <motion.div
          variants={combinedVariants}
          initial="initial"
          animate="animate"
          className="container relative p-4 mx-auto mb-10 font-sans rounded-lg "
        >
          <div className="grid grid-cols-4 gap-4 ">
            {/* Top Portion------ */}
            <div className="container relative col-span-4 p-2 bg-gray-800 rounded-lg min-h-96">
              <div className="grid h-full grid-cols-1 gap-2 md:grid-cols-4 ">
                <div className={`col-span-1 row-span-2 rounded-md`}>
                  <div className="h-full rounded-md shadow-md bg-gray-950">
                    <div className="p-4">
                      {/* Box 1 */}
                      <div>
                        <div className="flex-1 h-80 ">
                          {/* Content for Box 2 */}
                          <div className="flex items-center justify-center w-full mt-2 text-xl font-semibold text-gray-200 font-spaceG">
                            Current Utilisation Rate
                          </div>
                          <div className="flex items-center justify-center w-full h-full -mt-4">
                            <div className="flex w-64 h-64 font-spaceG">
                              <svg
                                className="w-full h-full"
                                viewBox="0 0 100 100 "
                              >
                                {/* <!-- Background circle --> */}
                                <circle
                                  className="text-gray-900 stroke-current"
                                  strokeWidth="3"
                                  cx="50"
                                  cy="50"
                                  r="40"
                                  fill="transparent"
                                ></circle>
                                {/* <!-- Progress circle --> */}
                                <circle
                                  className="text-indigo-600 stroke-current progress-ring__circle"
                                  strokeWidth="3"
                                  strokeLinecap="round"
                                  cx="50"
                                  cy="50"
                                  r="40"
                                  fill="transparent"
                                  strokeDasharray="251.2"
                                  strokeDashoffset={
                                    totalGPUs === 0
                                      ? 251.2
                                      : 251.2 *
                                        (1 - totalRentedGPUs / totalGPUs)
                                  }
                                ></circle>

                                {/* <!-- Center text --> */}
                                <text
                                  x="50"
                                  y="50"
                                  fontSize="12"
                                  textAnchor="middle"
                                  alignmentBaseline="middle"
                                  fill="#e5e7eb"
                                >
                                  {isNaN(totalRentedGPUs / totalGPUs)
                                    ? '0.0%'
                                    : `${(totalRentedGPUs / totalGPUs) * (100).toFixed(2)}%`}
                                </text>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className={`col-span-1 row-span-2 welcomebox  rounded-md`}>
                  <div className='h-full rounded-md shadow-md bg-gray-950'>
                    <div className='p-4'>
                      // Box 2
                      <div></div>
                    </div>
                  </div>
                </div> */}

                <div
                  className={`col-span-1 md:col-span-3 row-span-2 welcomebox rounded-md`}
                >
                  <div className="h-full rounded-md shadow-md bg-gray-950">
                    <div className="p-4">
                      {/* Box 4 */}
                      <div>
                        <div className="bg-transparent md:col-span-2 md:row-span-2 min-h-56 BIG">
                          <div className="h-full p-2 rounded-md shadow-md bg-gray-950">
                            <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-200 font-spaceG">
                              Income
                            </div>{' '}
                            <div className="w-full ">
                              {clusters ? (
                                <>
                                  {/* <DashboardSalesChart
                                    series={chartData.series}
                                    categories={chartData.categories}
                                  /> */}
                                  <ProviderColumnChart clusters={clusters} />
                                  <div className="flex flex-row items-center space-x-2 ">
                                    <div className="text-gray-400 dark:text-gray-400 ">
                                      Total Earned:
                                    </div>
                                    <div className="font-medium text-gray-100 dark:text-gray-100">
                                      {money(totalEarned)}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="flex flex-col items-center justify-center p-4 h-[250px] w-full border-2 border-gray-300 border-dashed rounded-lg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="36"
                                    height="36"
                                    className="fill-gray-400 bi bi-graph-up"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07"
                                    />
                                  </svg>
                                  <div className="flex flex-col mt-4 text-gray-300">
                                    <div>
                                      Looks like there is no data to display yet
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Tables*/}
            <div className="container relative col-span-4 p-2 bg-gray-800 rounded-lg ">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-4 md:grid-rows-2">
                {/* Div with "Usage Chart" */}

                {/* Div with table */}
                <div className="col-span-4 row-span-2 min-h-72 TABLE">
                  <div className="h-full p-2 rounded-md bg-gray-950">
                    {/* Table container with scroll */}
                    <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-300 font-spaceG">
                      Hardware
                    </div>
                    <section className="py-3 bg-gray-950 sm:py-5">
                      <div className="w-full">
                        {/* <!-- Start coding here --> */}
                        <div className="relative overflow-hidden shadow-md bg-gray-950 sm:rounded-lg">
                          <div className="flex flex-col items-center justify-between p-4 space-y-3 border-b border-gray-700 md:flex-row md:space-y-0 md:space-x-4">
                            <div className="flex items-center w-full space-x-3">
                              <h5 className="font-semibold text-white font-spaceG">
                                {/* here need to update the number of GPUs */}
                                {/* {''}
                                {Object.values(selectedListings).filter(
                                  (listing) => listing === true,
                                ).length > 0
                                  ? `${
                                      Object.values(selectedListings).filter(
                                        (listing) => listing === true,
                                      ).length
                                    } Selected`
                                  : displayedListings.length}{' '} */}
                                {totalGPUs} GPUs
                              </h5>
                            </div>
                            {/* button for adding listing */}
                            {/* <div className="flex flex-row items-center justify-end w-full space-x-3">
                              <button
                                // onClick={handleConfigureListing}
                                type="button"
                                className="flex items-center justify-center w-full px-3 py-2 text-sm font-medium text-white bg-indigo-700 rounded-lg md:w-auto hover:bg-indigo-800 dark:bg-indigo-600 dark:hover:bg-indigo-700 "
                              >
                                prev (Add new listing)
                              </button>
                              {isModalOpen && (
                                <ConfigureListingModal
                                  onClose={() => setIsModalOpen(false)}
                                />
                              )}
                            </div> */}
                          </div>
                          <div className="grid flex-col-reverse items-start justify-between grid-cols-2 py-4 border-b border-gray-700 md:px-4 md:flex md:flex-row md:items-center md:space-x-4">
                            <div className="flex items-end h-full md:block">
                              <Tooltip content="Actions will get applied to all listings selected with a checkbox">
                                <Dropdown
                                  label="Actions"
                                  // id='actionsDropdownButton'
                                  // data-dropdown-toggle='actionsDropdown'
                                  class="flex items-center justify-center w-full px-2 py-1 text-sm font-medium text-gray-400 !bg-gray-800 border border-gray-200 rounded-lg md:w-auto hover:text-indigo-700 hover:bg-gray-700 dark:!bg-gray-800"
                                  type="button"
                                >
                                  <Dropdown.Item
                                    onClick={() => openModal(true)}
                                  >
                                    Modify Price
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => openAvailModal(true)}
                                  >
                                    Change Availability
                                  </Dropdown.Item>
                                </Dropdown>
                              </Tooltip>
                            </div>
                            {/* <div className="grid w-full grid-cols-1 md:grid-cols-8 lg:w-2/3 md:gap-4">
                              <span className="block py-2.5 px-0 w-full text-sm bg-transparent appearance-none text-gray-400 col-start-1 col-span-1 md:text-right md:pr-2">
                                Filters:
                              </span> */}
                            {/* <div className="w-full md:col-span-3 md:col-start-2 xl:col-span-2">
                                <label htmlFor="number" className="sr-only">
                                  Number of GPUs
                                </label>
                                <select
                                  id="brand"
                                  onChange={handleGPUsChange}
                                  className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none text-gray-400 border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                >
                                  <option value="">Number of GPUs</option>
                                  <option value="1">1x</option>
                                  <option value="2">2x</option>
                                  <option value="4">4x</option>
                                  <option value="8">8x</option>
                                  <option value="16">16x</option>
                                  <option value="24">24x</option>
                                  <option value="32">32x</option>
                                  <option value="64">64x</option>
                                  <option value="128">128x</option>
                                </select>
                              </div> */}
                            {/* <div className="w-full md:col-span-2 md:col-start-5 xl:col-start-4">
                                <label htmlFor="price" className="sr-only">
                                  Price
                                </label>
                                <select
                                  id="price"
                                  onChange={handlePriceRangeChange}
                                  className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2  appearance-none text-gray-400 border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                  defaultValue=""
                                >
                                  <option value="">Price</option>
                                  <option value="below-1">
                                    {user?.currency_symbol} 0-1
                                  </option>
                                  <option value="below-2">
                                    {user?.currency_symbol} 1-2
                                  </option>
                                  <option value="below-4">
                                    {user?.currency_symbol} 3-4
                                  </option>
                                  <option value="over-5">
                                    {user?.currency_symbol} 5+
                                  </option>
                                </select>
                              </div> */}
                            {/* <div className="w-full md:col-span-2 md:col-start-7 xl:col-start-6">
                                <label htmlFor="status" className="sr-only">
                                  Status
                                </label>
                                <select
                                  id="status"
                                  onChange={handleStatusChange}
                                  className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none text-gray-400 border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                  defaultValue="allOnline"
                                >
                                  <option value="allOnline">All Online</option>
                                  <option value="">All</option>
                                  <option value="active">Rented</option>
                                  <option value="available">Available</option>
                                  <option value="unavailable">
                                    Unavailable
                                  </option>
                                </select>
                              </div> */}
                            {/* </div> */}
                          </div>
                          <div className="overflow-x-auto">
                            {/* TABLE GOES HERE */}
                            <ClusterTable
                              clusters={clusters}
                              selectedNode={selectedNode}
                              setSelectedNode={setSelectedNode}
                              selectedNodes={selectedNodes}
                              setSelectedNodes={setSelectedNodes}
                              selectAll={selectAll}
                              setSelectAll={setSelectAll}
                            />
                          </div>
                          <div
                            className="flex flex-col items-start justify-between px-4 pt-3 pb-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                            aria-label="Table navigation"
                          >
                            <div className="flex items-center space-x-5 text-xs">
                              <div>
                                <div className="mb-1 text-gray-400 dark:text-gray-400">
                                  Total Earned
                                </div>
                                <div className="font-medium dark:text-gray-200">
                                  {money(totalEarned)}
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center space-x-4">
                              {/* <button
                                type='button'
                                className='flex items-center px-3 py-2 text-xs font-medium text-center text-white bg-indigo-700 rounded-lg hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800'
                              >
                                Export CSV
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                {/* ------------------------------------------------------------------------ */}
                {/* Div with table */}
                <div className="col-span-4 row-span-2 mt-4 min-h-72 TABLE">
                  <div className="h-full p-2 rounded-md bg-gray-950">
                    {/* Table container with scroll */}
                    <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-300 font-spaceG">
                      Listings History
                    </div>
                    <div>
                      {hasPods ? (
                        <PodDetailsComponent
                          clusters={clusters}
                          totalEarned={totalEarned}
                          setTotalEarned={setTotalEarned}
                        />
                      ) : (
                        <div className="flex flex-col items-center justify-center p-4 h-[250px] w-full border-2 border-gray-300 border-dashed rounded-lg">
                          <p className="block mt-2 mb-4 text-sm font-semibold text-gray-300">
                            If you're experiencing any issues onboarding your
                            hardware or getting rentals, please contact support.
                          </p>
                          <div className="flex space-x-4">
                            <Link
                              to="/marketplace"
                              className="flex text-gray-700 hover:text-white border border-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-gray-500 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 whitespace-nowrap justify-center"
                            >
                              Visit Marketplace
                            </Link>
                            <button
                              onClick={handleToggleEmailModal}
                              className="text-gray-700 hover:text-white border border-gray-700 hover:bg-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-gray-500 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 whitespace-nowrap"
                            >
                              Get Help
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        {/* {showActionModal && (
          <ActionModal
            // action={modalAction}
            isOpen={showActionModal}
            onConfirm={() => endInstance(selectedInstanceId)}
            onCancel={() => setIsStopped(false)}
            onClose={() => setShowActionModal(false)}
          />
        )} */}
        {isMultiPriceModalOpen && (
          <Modal
            show={openModal}
            onClose={() => setIsMultiPriceModalOpen(false)}
          >
            <Modal.Header className="rounded-t-lg bg-gray-950">
              <h3 className="text-lg text-gray-200 font-spaceG">
                Modify Prices of
                {(() => {
                  const selectedCount = Object.values(selectedNodes).filter(
                    (isSelected) => isSelected,
                  ).length;
                  if (selectedCount === 0) {
                    return ' 0 Selected Nodes';
                  } else if (selectedCount === 1) {
                    return ' 1 Selected Node';
                  } else {
                    return ` ${selectedCount} Selected Nodes`;
                  }
                })()}
              </h3>
            </Modal.Header>

            <Modal.Body className=" bg-gray-950">
              <div className="flex flex-col items-start my-2">
                {/* <div>
                  <label className="text-xl text-gray-200 font-spaceG">
                    {priceChangePercentage}%
                  </label>
                </div> */}
                {/* <div>
                  <input
                    className="h-1 bg-gray-900 appearance-none w-96 "
                    type="range"
                    min="0"
                    max="100"
                    value={priceChangePercentage}
                    onChange={(e) => setPriceChangePercentage(e.target.value)}
                  />
                </div> */}
                <div>
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium leading-6 text-gray-200"
                  >
                    Price per GPU
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      value={newPriceInput}
                      onChange={(e) => setNewPriceInput(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-950"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        USD
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="bg-gray-950">
              <div className="flex items-center justify-center ">
                <button
                  className="w-24 h-10 mx-2 text-gray-200 bg-gray-800 border border-gray-800 rounded-lg shadow-sm hover:bg-gray-700"
                  onClick={confirmPriceChange}
                  disabled={
                    !Object.values(selectedNodes).some(
                      (isSelected) => isSelected,
                    )
                  }
                >
                  Confirm
                </button>
                <button
                  className="w-24 h-10 mx-2 text-gray-100 border border-gray-700 rounded-lg hover:bg-gray-900"
                  onClick={() => setIsMultiPriceModalOpen(false)}
                >
                  Cancel
                </button>{' '}
                {
                  // Check if no listings are selected and display the message
                  !Object.values(selectedNodes).some(
                    (isSelected) => isSelected,
                  ) && (
                    <span className="ml-6 text-sm text-gray-100">
                      First select one or more listings using the checkbox
                    </span>
                  )
                }
              </div>
            </Modal.Footer>
          </Modal>
        )}
        {openAvailModal && (
          <Modal show={availModal} onClose={() => setAvailModal(false)}>
            <Modal.Header className="rounded-t-lg bg-gray-950">
              <h3 className="text-lg text-gray-200 font-spaceG">
                {(() => {
                  const selectedCount = Object.values(selectedNodes).filter(
                    (isSelected) => isSelected,
                  ).length;
                  if (selectedCount === 0) {
                    return ' 0 Selected Nodes';
                  } else if (selectedCount === 1) {
                    return ' 1 Selected Node';
                  } else {
                    return ` ${selectedCount} Selected Nodes`;
                  }
                })()}
              </h3>
            </Modal.Header>

            <Modal.Body className=" bg-gray-950">
              <div className="flex flex-col items-start my-2">
                <RadioGroup
                  value={selectedAvailability}
                  onChange={setSelectedAvailability}
                >
                  <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-200">
                    Availability
                  </RadioGroup.Label>

                  <div className="grid grid-cols-1 mt-4 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    {/* Option for Available */}
                    <RadioGroup.Option
                      value="available"
                      className={({ active }) =>
                        classNames(
                          active
                            ? 'border-indigo-600 ring-2 ring-indigo-600'
                            : 'border-gray-300',
                          'relative flex cursor-pointer rounded-lg border  p-4 shadow-sm focus:outline-none',
                        )
                      }
                    >
                      {({ checked }) => (
                        <>
                          <RadioGroup.Label
                            as="span"
                            className="block text-sm font-semibold text-gray-200"
                          >
                            Available
                            <div className="text-sm font-normal text-gray-300">
                              The GPUs in this node will be available for users
                              to rent.
                            </div>
                          </RadioGroup.Label>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? 'invisible' : '',
                              'h-5 w-5 text-indigo-600',
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              'pointer-events-none absolute -inset-px rounded-lg',
                              checked
                                ? 'border-indigo-600'
                                : 'border-transparent',
                              'border-2',
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>

                    {/* Option for Unavailable */}
                    <RadioGroup.Option
                      value="unavailable"
                      className={({ active }) =>
                        classNames(
                          active
                            ? 'border-indigo-600 ring-2 ring-indigo-600'
                            : 'border-gray-300',
                          'relative flex cursor-pointer rounded-lg border  p-4 shadow-sm focus:outline-none',
                        )
                      }
                    >
                      {({ checked }) => (
                        <>
                          <RadioGroup.Label
                            as="span"
                            className="block text-sm font-semibold text-gray-200"
                          >
                            Unavailable
                            <div className="text-sm font-normal text-gray-300">
                              The GPUs in this node will NOT be available to the
                              platform.
                            </div>
                          </RadioGroup.Label>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? 'invisible' : '',
                              'h-5 w-5 text-indigo-600',
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              'pointer-events-none absolute -inset-px rounded-lg',
                              checked
                                ? 'border-indigo-600'
                                : 'border-transparent',
                              'border-2',
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  </div>
                </RadioGroup>
              </div>
            </Modal.Body>
            <Modal.Footer className="bg-gray-950">
              <div className="flex items-center justify-center ">
                <button
                  className="w-24 h-10 mx-2 text-gray-200 bg-gray-800 border border-gray-800 rounded-lg shadow-sm hover:bg-gray-700"
                  onClick={() => confirmAvailChange(selectedAvailability)}
                  disabled={
                    !Object.values(selectedNodes).some(
                      (isSelected) => isSelected,
                    )
                  }
                >
                  Confirm
                </button>
                <button
                  className="w-24 h-10 mx-2 text-gray-100 border border-gray-700 rounded-lg hover:bg-gray-900"
                  onClick={() => setAvailModal(false)}
                >
                  Cancel
                </button>{' '}
                {
                  // Check if no listings are selected and display the message
                  !Object.values(selectedNodes).some(
                    (isSelected) => isSelected,
                  ) && (
                    <span className="ml-6 text-sm text-gray-100">
                      First select one or more listings using the checkbox
                    </span>
                  )
                }
              </div>
            </Modal.Footer>
          </Modal>
        )}
        <EmailModal
          userEmail={userEmail}
          showModal={showEmailModal}
          onClose={handleToggleEmailModal}
          alternativeMessage="If you're running into technical difficulties while trying to onboard your hardware or make a sale- reach out and we'll find a solution."
        />
      </div>
    </div>
  );
};

export default NewProviderDashboard;
