import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-hot-toast';

import { checkTokenExpiration } from './utils/userUtils';
import { getDefaultLocaleForCurrency } from './utils/localeCurrencyMapping';
import { getCurrencySymbol } from './utils/CurrencyUtils';
import { CustomError } from './utils/errors';
import { setupInterceptors } from './utils/AxiosInstance';
const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

// Create the context
const UserContext = createContext(null);

// Custom hook to use the user context
// eslint-disable-next-line react-refresh/only-export-components
export const useUser = () => useContext(UserContext);

// Provider component
/**
 * @module UserProvider
 * @description A component to provide the user context to its children
 * @param {Object} props
 * @param {React.ReactNode} props.children - The children components to be wrapped
 * @returns {JSX.Element}
 */
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showGetVerifiedModal, setShowGetVerifiedModal] = useState(false);
  const [displayCurrency, setDisplayCurrency] = useState(
    localStorage.getItem('displayCurrency') ?? 'USD',
  );
  const [token, setToken] = useState(localStorage.getItem('token'));

  const customAxios = axios.create({
    baseURL: frontendServerUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  customAxios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        const isExpired = checkTokenExpiration(token);
        if (isExpired) {
          localStorage.setItem('shouldRedirect', 'no');
          setShowTokenExpiredModal(true);
          logout();
          return Promise.reject(
            new CustomError({
              message: 'Token is expired',
              code: 'no-valid-token',
            }),
          );
        }
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      } else {
        setShowSignInModal(true);
        logout();
        return Promise.reject(
          new CustomError({
            message: 'No token found',
            code: 'no-valid-token',
          }),
        );
      }
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  customAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        setShowTokenExpiredModal(true);
        logout();
      }

      if (!error.response) {
        return Promise.reject(
          new CustomError({
            message: error.message,
            code: error.code,
          }),
        );
      }
      return Promise.reject(
        new CustomError({
          message: error.response.data.message,
          code: error.response.data.code || 'ERR_UNKNOWN',
          status: error.response.status,
        }),
      );
    },
  );

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('userDetails');
    // TODO: call the logout endpoint to invalidate the token
    // const response = await customAxios.get('/auth/logout');
  }, []);

  const updateUserDetails = async (userDetails) => {
    const prevUserDetails = user ? { ...user } : {};
    const newUserDetails = { ...userDetails };

    if (newUserDetails.currency) {
      localStorage.setItem('displayCurrency', newUserDetails.currency);
      setDisplayCurrency(newUserDetails.currency);
    } else if (localStorage.getItem('displayCurrency')) {
      newUserDetails.currency = localStorage.getItem('displayCurrency');
    } else {
      newUserDetails.currency = 'USD';
      localStorage.setItem('displayCurrency', 'USD');
      setDisplayCurrency('USD');
    }

    newUserDetails.new_credits_available =
      +newUserDetails.new_credits_available;

    newUserDetails.locale = getDefaultLocaleForCurrency(
      newUserDetails.currency,
    ).locale;
    newUserDetails.currency_symbol = getCurrencySymbol(
      newUserDetails.currency,
      newUserDetails.locale,
    );
    const approvedStatuses = ['verified', 'approved'];
    const unverifiedStatuses = [
      'declined',
      'abandoned',
      'expired',
      'started',
      'resubmission_requested',
      'unverified',
    ];
    const pendingStatuses = ['submitted', 'review', 'pending'];

    newUserDetails.kyc_status = approvedStatuses.includes(
      newUserDetails.kyc_status,
    )
      ? 'approved'
      : pendingStatuses.includes(newUserDetails.kyc_status)
        ? 'pending'
        : 'unverified';

    newUserDetails.jwt = { ...jwtDecode(localStorage.getItem('token')) };

    if (JSON.stringify(prevUserDetails) !== JSON.stringify(newUserDetails)) {
      setUser(newUserDetails);
      localStorage.setItem('userDetails', JSON.stringify(newUserDetails));
      return newUserDetails;
    }

    return prevUserDetails;
  };

  const fetchAndUpdateUserDetails = async () => {
    let user;
    try {
      const response = await customAxios.get('profile');
      user = updateUserDetails(response.data);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error fetching user details:', error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', {
          id: 'network-error',
        });
        return;
      }
    }
    return user;
  };

  // useEffect(() => {
  //   setupInterceptors({
  //     token,
  //     checkTokenExpiration,
  //     logout,
  //     setShowSignInModal,
  //     setShowTokenExpiredModal,
  //   });
  // }, [token, logout]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        logout,
        customAxios,
        showTokenExpiredModal,
        setShowTokenExpiredModal,
        showSignInModal,
        setShowSignInModal,
        checkTokenExpiration,
        showGetVerifiedModal,
        setShowGetVerifiedModal,
        updateUserDetails,
        fetchAndUpdateUserDetails,
        displayCurrency,
        setDisplayCurrency,
        refresh,
        setRefresh,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
