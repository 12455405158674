import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';

import ErrorMessageInsideModal from '../Components/ErrorMessageInsideModal';
import Spinner from '../Components/Spinner';
import { sanitizeEmail } from '../utils/userUtils';
const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!emailIsValid) {
      setErrorMessage('Please enter a valid email address');
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${frontendServerUrl}/forgot-password`, {
        email: await sanitizeEmail(email),
        // email,
      });
      setTimeout(() => {
        toast('If the email exists, a password reset link will be sent');
        setLoading(false);
        navigate('/');
      }, 1000);
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        console.error('Error: ', error);
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
    }
  };

  return (
    <div className="flex flex-row w-full max-h-screen sm:min-h-[calc(100vh-88px-118px)] min-h-[calc(100vh-88px-130px)] lg:min-h-[calc(100vh-96px-118px)] p-10 overflow-y-hidden bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col justify-center p-10 mx-auto bg-white border rounded-lg shadow-md font-spaceG h-fit dark:bg-gray-800">
        <h2 className="mb-4 text-2xl font-semibold ">Reset Password</h2>
        <h2 className="mb-10 text-lg text-left">
          Please enter your email address to receive a password reset link.
        </h2>
        <form onSubmit={handleSubmit} className="w-full mx-auto">
          <label
            htmlFor="email-address-icon"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your Email
          </label>
          <input
            type="text"
            id="email-address-icon"
            className={clsx(
              errorMessage ? 'mb-4' : 'mb-10',
              'block w-full mt-1 border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300',
            )}
            placeholder="name@company.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMessage('');
            }}
            required
          />

          <ErrorMessageInsideModal errorMessage={errorMessage} />

          <button
            type="submit"
            disabled={loading}
            className={
              'text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm px-4 h-10 text-center inline-flex items-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800 w-full justify-center'
            }
          >
            {loading ? <Spinner /> : 'Send Reset Link'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
