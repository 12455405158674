export function prepareChartData(
  tokenData,
  systemOnOffTimes,
  selectedMonth,
  modelCreatedAtUnix,
) {
  const tokenDataMap = new Map(
    tokenData.map(({ date, tokens }) => [new Date(date).toISOString(), tokens]),
  );

  const { year, month } = selectedMonth; // { year: 2023, month: 9 } for October 2023
  const startDate = new Date(Date.UTC(year, month, 1));
  const endDate = new Date(Date.UTC(year, month + 1, 1));

  const hoursInMonth = [];
  for (
    let d = new Date(startDate);
    d < endDate;
    d.setUTCHours(d.getUTCHours() + 1)
  ) {
    hoursInMonth.push(new Date(d));
  }

  let systemOnOffPeriods = [];

  if (!systemOnOffTimes || systemOnOffTimes.length === 0) {
    if (modelCreatedAtUnix && modelCreatedAtUnix > startDate.getTime()) {
      systemOnOffPeriods = [[modelCreatedAtUnix, endDate]];
    } else if (modelCreatedAtUnix && modelCreatedAtUnix < startDate.getTime()) {
      systemOnOffPeriods = [[startDate, endDate]];
    } else {
      systemOnOffPeriods = [[startDate, endDate]];
    }
  } else {
    systemOnOffPeriods = systemOnOffTimes;
  }

  const systemPeriods = systemOnOffPeriods.map(([on, off]) => ({
    on: new Date(on),
    off: off ? new Date(off) : endDate,
  }));

  const result = hoursInMonth.map((hour) => {
    const hourISOString = hour.toISOString();
    const tokens = tokenDataMap.get(hourISOString);

    const isDeployed = systemPeriods.some(
      ({ on, off }) => on <= hour && hour < off,
    );

    const result = {
      x: hourISOString,
      y:
        hour > new Date()
          ? null
          : tokens !== undefined
            ? tokens
            : isDeployed
              ? 0
              : null,
    };

    return result;
  });

  return result;
}
