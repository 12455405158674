import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

const usePaymentLink = ({ url = '' }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const token = url ? url.split('link/')[1] : null;

  useEffect(() => {
    if (
      !token ||
      token.split('-').length !== 5 ||
      token.length !== 36 ||
      typeof token !== 'string'
    ) {
      setError('Invalid payment link');
    } else {
      setError('');
    }
  }, [token]);

  const redirectToPaymentLink = useCallback(() => {
    const fetchPaymentLink = async () => {
      if (!token) {
        setError('Invalid payment link');
        return;
      }
      try {
        setLoading(true);
        const response = await axios.get(
          `${frontendServerUrl}/generate_payment_link/${token}`,
          { headers: { 'Content-Type': 'application/json' } },
        );

        window.location.href = response.data.url;
      } catch (error) {
        console.error('Error fetching payment link:', error);
        if (error.code === 'ERR_NETWORK') {
          setError('Network error. Please try again later.', { id: 'network-error'});
          return;
        }
        setError(
          error.response?.data?.message || 'Error redirecting to payment link',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentLink();
  }, [token]);

  return { loading, error, redirectToPaymentLink };
};

export default usePaymentLink;
