import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { useUser } from '../../UserContext';

const locations = [
  { id: 'default', title: 'Default' },
  { id: 'uk', title: 'UK' },
  { id: 'europe', title: 'Europe' },
];

const SettingsLocation = () => {
  const { customAxios, user, setUser, fetchAndUpdateUserDetails } = useUser();
  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {
    if (user) {
      setSelectedLocation(user.location_preference);
    }
  }, [user]);

  const updateField = async (value) => {
    const payload = {
      location_preference: value,
    };

    try {
      const response = await customAxios.post(
        '/set_location_preference',
        payload,
      );

      await fetchAndUpdateUserDetails();

      toast.success(`Location updated`);
    } catch (error) {
      console.error(`Error updating ${location}:`, error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', { id: 'network-error'});
        return;
      }
      toast.error(`Error updating ${location}`);
    }
  };

  const handleLocationChange = (event) => {
    const newLocation = event.target.value;
    setSelectedLocation(newLocation);
    updateField(newLocation);
  };

  return (
    <div className="w-full min-h-screen p-5 mx-auto border rounded-lg rightside ">
      <header className="pt-10 pb-4 lg:pt-0">
        <h2 className="font-sans text-xl font-medium leading-tight tracking-tight text-slate-900 dark:text-white">
          Location
        </h2>
        {/* <hr className="border-t border-zinc-300" /> */}
      </header>

      <div className="p-4">
        <div className="text-gray-500 font-sans text-sm  xl:max-w-[1000px]">
          <p>
            Select the physical location where you want to deploy your models.
            Choosing the right location can improve performance and reduce
            latency.
          </p>

          <h2 className="mt-6 font-semibold text-gray-900">Default Location</h2>
          <p className="mt-2 font-sans text-sm text-gray-500">
            If you do not have a specific preference, we recommend using the
            default location. The default location is optimized to provide the
            best overall performance and reliability.
          </p>
        </div>
        <div className="my-8"></div>
        <fieldset>
          <legend className="font-semibold text-gray-900 ">Locations</legend>
          <p className="mt-1 font-sans text-sm text-gray-500">
            We believe companies should be able to control the geographic
            location of their data.
          </p>
          <p className="mt-1 font-sans text-sm text-gray-500">
            Your data will not leave the location that you select below.
          </p>
          <div className="mt-6 space-y-6">
            {locations.map((location) => (
              <div key={location.id} className="flex items-center">
                <input
                  id={location.id}
                  name="location"
                  type="radio"
                  value={location.id}
                  checked={selectedLocation === location.id}
                  onChange={handleLocationChange}
                  className="w-4 h-4 border-gray-300 text-zinc-600 focus:ring-zinc-600"
                />
                <label
                  htmlFor={location.id}
                  className="block ml-3 text-sm font-medium text-gray-900"
                >
                  {location.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      <div className="p-4 my-2 rounded-md bg-indigo-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              aria-hidden="true"
              className="w-5 h-5 text-indigo-400"
            />
          </div>
          <div className="flex-1 ml-3 md:flex md:justify-between">
            <p className="text-sm text-indigo-700">
              Note: the base models in Playground are not constrained by the
              location preferences you set and you will still be able to
              interact with them.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsLocation;
